"use client"

import styled from "@emotion/styled"
import { BlockRenderer, Container, CustomBreadcrumbs, ImageTextBlock, TopBanner } from "ui"
import { getBreadCrumbs } from "utils"
import { findProperty } from "utils/findProperty"
import Certificates from "../Standards/Certificates"
import ContentText from "./ContentText"
import DynamicSlider from "./DynamicSlider"
import EligibilityAccordion from "./EligibilityAccordion"
import EligibilityForm from "./EligibilityForm"
import EligibleProjects from "./EligibleProjects"
import HistoryBlock from "./HistoryBlock"
import MainTitle from "./MainTitle"
import TopBannerEligible from "./TopBannerEligible"

const StyledTopDiv = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 98px;
  }
`

type Props = {
  locale: string
  data: any
  companyId?: number
  hideBreadCrumbs?: boolean
}

export const Smp: React.FC<Props> = ({ data, locale, hideBreadCrumbs = false }: Props) => {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  const components = {
    topBanner: TopBanner,
    pgeMainTitle: MainTitle,
    contentText: ContentText,
    dynamicSlider: DynamicSlider,
    historyBlock: HistoryBlock,
    imageTextBlock: ImageTextBlock,
    certificates: Certificates,
    topBanner1: TopBannerEligible,
    eligibleProjectsBlock: EligibleProjects,
    eligibilityInformationItem1: EligibilityAccordion,
    eligibilityFormBlock: EligibilityForm,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  const hasBanner = blocks?.some(
    (block: any) => block.blockType === "topBanner" || block.blockType === "HeaderWithHeroAndText",
  )
  return (
    <>
      {!hideBreadCrumbs ? (
        <Container>
          <CustomBreadcrumbs current={selfObject} parents={parents} />
        </Container>
      ) : (
        <StyledTopDiv></StyledTopDiv>
      )}
      {blocks?.map((block: any, index: number) => (
        <BlockRenderer
          key={index}
          blockType={block.blockType}
          blockData={block}
          components={components}
          props={
            block.blockType === "numbersComponent1"
              ? {
                  accentColor: "black",
                }
              : block.blockType === "topBanner"
              ? { videoHeight: "272px", imageHeight: "272px" }
              : block.blockType === "contentText" || block.blockType === "pgeMainTitle"
              ? {
                  noTransition: true,
                  hasBanner,
                  noBackground: true,
                  definedMargin: "112px",
                  linkColor: "rgba(91, 117, 83, 1)",
                }
              : {}
          }
        />
      ))}
    </>
  )
}

export default Smp

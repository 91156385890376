import styled from "@emotion/styled"
import { css, FormHelperText } from "@mui/material"

import { StyledComponent } from "@emotion/styled"
import { ReactNode } from "react"

export const StyledHelperText: StyledComponent<
  { status: boolean; children: ReactNode; id?: string; marginTop?: string },
  {},
  {}
> = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "status" && prop !== "marginTop",
})<{ status: boolean; marginTop?: string }>`
  margin-top: 4px;
  margin-bottom: 16px;
  position: static;
  color: ${(props) => (props.status ? "#008015" : "#C92418")};
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
`

export const StyledForm = styled.form`
  width: 100%;

  input {
    font-size: 18px;
  }
  label {
    font-size: 18px;
    span {
      font-size: 18px;
    }
  }
`
export const StyledAcceptBox = styled.div`
  max-height: 370px;
  overflow-y: auto;
  border: 1px solid black;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  font-size: 18px;
  background-color: #fff;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-inline-end: 56px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.propBlue};
    border: 3px solid #fff;
    border-radius: 200px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.propBlue};
  }
  /* line-height: 1.5; */
`

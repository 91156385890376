"use client"

import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import { Container, NewText, styleObjectToCss, useInViewConfig, useTransitionUpStyles } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty } from "utils"

const StyledContainer = styled.div<{ definedMargin?: string }>`
  margin-top: ${(p) => (p.definedMargin ? "56px" : "32px")};
  margin-bottom: ${(p) => (p.definedMargin ? "0px" : "32px")};
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: ${(p) => (p.definedMargin ? p.definedMargin : "40px")};
    margin-bottom: ${(p) => (p.definedMargin ? "0px" : "40px")};
  }
`
type Props = {
  data: any
  noTransition?: boolean
  definedMargin?: string
}

function MainTitle({ data, noTransition, definedMargin }: Props) {
  const { ref, inView } = useInView(useInViewConfig)

  const mainTitle = extractProperty(data, "mainTitle")
  const titleTransitionStyles = useTransitionUpStyles(noTransition || inView)
  return (
    <StyledContainer ref={ref} definedMargin={definedMargin}>
      <Container>
        <NewText
          component="h1"
          desktopFontSize={40}
          desktopLineHeight="36px"
          mobileFontSize={32}
          mobileLineHeight="36px"
          color={sharedColors.black}
          cssString={styleObjectToCss(titleTransitionStyles)}
        >
          {mainTitle}
        </NewText>
      </Container>
    </StyledContainer>
  )
}

export default MainTitle

import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material"
import { FormFieldDto } from "api"
import { Controller } from "react-hook-form"
import { CheckMark2Icon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { StyledHelperText } from "../StyledComponents"
import { FormFieldSettingsProps } from "../utils/interfaces"

interface Props {
  field: FormFieldDto
  control: any
  error: any
  isSubmitted: boolean
}

const RadioField = ({ field, control, error, isSubmitted }: Props) => {
  const settings = field.settings as unknown as FormFieldSettingsProps

  return (
    <FormControl fullWidth error={!!error}>
      {settings.showLabel === "True" && (
        <InputLabel
          htmlFor={field.id}
          sx={{
            position: "relative",
            transform: "none",
            inset: 0,
            marginTop: 4,
            color: `${sharedColors.black} !important`,
            whiteSpace: "normal",
          }}
        >
          {field.helpText}
        </InputLabel>
      )}
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            row
            value={value || ""}
            onChange={onChange}
            aria-labelledby={field.id}
            aria-invalid={!!error}
          >
            {field.preValues.map((preValue) => (
              <FormControlLabel
                key={preValue.value}
                value={preValue.value}
                control={
                  <Radio
                    sx={{
                      color: "black",

                      "&.Mui-checked": {
                        color: sharedColors.propBlue,
                      },
                    }}
                  />
                }
                label={preValue.value} // replaced preValue.caption with preValue.value
                labelPlacement="end" // Ensure label is placed correctly
              />
            ))}
          </RadioGroup>
        )}
      />
      <StyledHelperText status={!error}>
        {isSubmitted && error && (
          <>
            <CheckMark2Icon state={!error} />
            <span>{error?.message as string}</span>
          </>
        )}
      </StyledHelperText>
    </FormControl>
  )
}

export default RadioField

import styled from "@emotion/styled"
import { Button, FormHelperText } from "@mui/material"
import { FormFieldDto } from "api"
import { ChangeEvent, useState } from "react"
import { FieldError, UseFormSetValue } from "react-hook-form"
import { CheckMark2Icon, CircleXIcon, FileIcon, NewText } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { FormFieldSettingsProps } from "../utils/interfaces"

const StyledUplaodWrapper = styled.div<{ status: boolean }>`
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 1px dashed ${(props) => (props.status ? "#c92418" : "black")};
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  cursor: pointer;
  input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`

const StyledFileName = styled.div`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 150px;
  }
`

const StyledButton = styled(Button)<{ component: string }>`
  color: ${colors.blue};
  padding: 0px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 18px;
  color: black;
  text-decoration: none;
  height: 100%;
  font-weight: 400;
  height: 56px;
  width: 100%;
  justify-content: flex-start;
  padding-right: 16px;
  padding-left: 16px;
  line-height: 1;
  :hover {
    text-decoration: none;
  }
  svg {
    margin-right: 8px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 24px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    font-size: 16px;
  }
`

const StyledDiv = styled.div`
  width: 100%;

  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    position: relative;
  }
`

const StyledHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  position: relative;
  bottom: unset;
  color: ${(props) => (props.status ? "#C92418" : "#008015")};
  font-size: 12px;
  line-height: 1.1;
`

const StyledTextResponse = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

interface Props {
  setValue: UseFormSetValue<any>
  formError?: FieldError
  isSubmitted?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string[]) => void
  helpText: string
  field: FormFieldDto
}

const FileUpload = ({ setValue, formError, onChange, helpText, field }: Props) => {
  const settings = field?.settings as unknown as FormFieldSettingsProps
  const [fileNames, setFileNames] = useState<string[]>([])
  const [fileError, setFileError] = useState<string>("")

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || [])
    if (selectedFiles.length) {
      const { allowedUploadExtensions, allowAllUploadExtensions } = field.fileUploadOptions
      const maxSizeInBytes = 5 * 1024 * 1024 // 5MB

      const validFiles = selectedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase()
        const fileSize = file.size

        if (
          (!allowAllUploadExtensions && !allowedUploadExtensions.includes(fileExtension || "")) ||
          fileSize > maxSizeInBytes
        ) {
          setFileError(field.patternInvalidErrorMessage)
          return false
        }
        return true
      })
      if (validFiles.length) {
        setFileError("")
        setFileNames(validFiles.map((file) => file.name))
        setValue(
          `${field.id}_filenames`,
          validFiles.map((file) => file.name),
          { shouldValidate: true }, // Save the file names as an array with the prefix
        )

        const base64Promises = validFiles.map((file) => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = reject
            reader.readAsDataURL(file)
          })
        })

        Promise.all(base64Promises)
          .then((base64Files) => {
            onChange(base64Files)
          })
          .catch((error) => {
            console.error("Error converting files to base64:", error)
          })
      } else {
        setValue(`${field.id}_filenames`, [], { shouldValidate: true }) // Clear the file names with the prefix
      }
    }
  }

  const error = !!fileError || !!formError?.message
  return (
    <StyledDiv>
      <StyledUplaodWrapper status={error}>
        <StyledButton
          component="label"
          aria-label={helpText}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault()
              e.currentTarget.click()
            }
          }}
        >
          {fileNames.length ? (
            <>
              <CircleXIcon color="black" />
              <StyledFileName>{fileNames.join(", ")}</StyledFileName>
            </>
          ) : (
            <>
              <FileIcon />
              {helpText}
              {field.required && " *"}
            </>
          )}
          <input
            type="file"
            onChange={handleFileInputChange}
            accept={
              field.fileUploadOptions.allowAllUploadExtensions
                ? "*"
                : field.fileUploadOptions.allowedUploadExtensions.map((ext) => `.${ext}`).join(", ")
            }
            multiple={field.fileUploadOptions.allowMultipleFileUploads}
            aria-hidden="true"
            tabIndex={-1}
          />
        </StyledButton>
      </StyledUplaodWrapper>
      <NewText
        component="p"
        desktopFontSize={14}
        desktopLineHeight="20px"
        desktopFontWeight={400}
        color={colors.black}
        mobileFontSize={12}
      >
        {settings.selectedFilesListHeading}
      </NewText>
      {}
      <StyledHelperText status={error}>
        {error ? (
          <StyledTextResponse>
            <CheckMark2Icon state={!error} />
            <span>{error && fileError ? fileError : formError?.message}</span>
          </StyledTextResponse>
        ) : null}
      </StyledHelperText>
    </StyledDiv>
  )
}

export default FileUpload

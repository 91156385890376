import styled from "@emotion/styled"
import { useInfiniteQuery } from "@tanstack/react-query"
// import { Filters, JobsObject, fetchJobs } from "library/api"
import { useState } from "react"
import { Container } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { JobsObject } from "utils"
import { fetchOnlyJobs } from "utils/api"
import { CareerLobbyMessages } from "../Careers"
import FiltersComponent from "./FiltersComponent"
import JobsDisplay from "./JobsDisplay"

const JobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
  padding-top: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`
const ButtonContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`
const StyledButton = styled.button`
  text-decoration: none;
  background-color: transparent;
  color: ${colors.black};
  font-size: 18px;
  cursor: pointer;
  border: none; // This ensures no borders are applied
  border-bottom: 1px solid black; // This applies only a bottom border
`
export type FilterState = {
  areaIds: any
  companyIds: any
  jobScopeIds: any
  professionIds: any
  orderIds: any
  companyDivisions: any
}

const filterParams = (params: FilterState) => {
  return (
    Object.entries(params)
      /* eslint-disable @typescript-eslint/no-unused-vars */
      // eslint-disable-next-line no-unused-vars
      .filter(([_key, value]) => value !== 0)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  )
}

type Props = {
  jobs: JobsObject
  filters: any
  locale: string
  messages: CareerLobbyMessages
  allData: any
  companyDivisionId: number[]
  isGroupWebsite?: boolean
}
function Jobs({ filters, locale, messages, allData, companyDivisionId, isGroupWebsite }: Props) {
  const [state, setState] = useState<FilterState>({
    areaIds: [],
    companyIds: [],
    jobScopeIds: [],
    professionIds: [],
    orderIds: [],
    companyDivisions: [],
  })

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery<any>({
      queryKey: ["jobs", state],
      queryFn: async ({ pageParam = 0 }) => {
        const params = {
          ...filterParams({ ...state, companyDivisions: companyDivisionId }),
          requestPage: pageParam,
        }
        return await fetchOnlyJobs(params)
      },
      getNextPageParam: (lastPage) => {
        if (lastPage.hasNextPage) {
          return lastPage.pageIndex + 1
        }
        return undefined
      },
      initialData: {
        pages: [allData],
        pageParams: [0],
      },
      initialPageParam: 0,
      refetchOnWindowFocus: false,
    })
  const results = data.pages.map((el) => el.items).flat()

  const totalaCount = data?.pages[0]?.totalCount

  return (
    <Container>
      <JobsContainer>
        {allData?.jobs?.items.length > 0 && (
          <FiltersComponent
            filters={filters}
            setState={setState}
            state={state}
            messages={messages}
            companyDivisionId={companyDivisionId}
          />
        )}
        <JobsDisplay
          totalCount={totalaCount}
          jobs={[...results]}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          locale={locale}
          messages={messages}
          filters={filters}
          isGroupWebsite={isGroupWebsite}
        />
      </JobsContainer>
      {hasNextPage && (
        <ButtonContainer>
          <StyledButton onClick={() => fetchNextPage()}>{messages.more}</StyledButton>
        </ButtonContainer>
      )}
    </Container>
  )
}

export default Jobs

interface FormFieldSettingsProps {
  autocompleteAttribute: string
  defaultValue: string
  fieldType: string
  maximumLength: string
  placeholder: string
  showLabel: string
  acceptCopy?: string
}

export const getAutocompleteAttribute = (
  alias: string,
  fieldType: string,
  settings: FormFieldSettingsProps,
): string => {
  if (settings.autocompleteAttribute) {
    return settings.autocompleteAttribute
  }

  switch (fieldType) {
    case "email":
      return "email"
    case "tel":
    case "phone":
      return "tel-national"
    case "text":
    case "number":
      if (alias.toLowerCase().includes("email")) {
        return "email"
      }
      if (alias.toLowerCase().includes("phone")) {
        return "tel-national"
      }
      if (alias.toLowerCase().includes("last")) {
        return "family-name"
      }
      if (alias.toLowerCase().includes("first")) {
        return "given-name"
      }
      if (alias.toLowerCase().includes("city")) {
        return "address-level2"
      }
      if (alias.toLowerCase().includes("street")) {
        return "address-line1"
      }
      if (alias.toLowerCase().includes("zip")) {
        return "postal-code"
      }
      break
    default:
      return "off"
  }

  return "off"
}

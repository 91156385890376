import { FormFieldDto, FormFieldsetDto } from "api"
import { evaluateCondition } from "./evaluateCondition"

export function isFieldVisible(field: FormFieldDto, values: Record<string, any>) {
  const shouldShow = evaluateCondition(field.condition, values)
  if (field.condition?.actionType === "Show" && !shouldShow) {
    return false
  }
  if (field.condition?.actionType === "Hide" && shouldShow) {
    return false
  }
  return true
}

export function isFieldsetVisible(fieldset: FormFieldsetDto, values: Record<string, any>) {
  if (!fieldset.condition?.rules?.length) return true
  const shouldShow = evaluateCondition(fieldset.condition, values)
  if (fieldset.condition.actionType === "Show" && !shouldShow) return false
  if (fieldset.condition.actionType === "Hide" && shouldShow) return false
  return true
}

import { FilledInput, FormControl, InputLabel } from "@mui/material"
import { FormFieldDto } from "api"
import { useLocale } from "next-intl"
import { Controller } from "react-hook-form"
import { CheckMark2Icon } from "ui"
import { StyledHelperText } from "../StyledComponents"
import { getAutocompleteAttribute } from "../utils/autocompleteUtils"
import { FormFieldSettingsProps } from "../utils/interfaces"

interface Props {
  field: FormFieldDto
  control: any
  error: any
  isSubmitted: boolean
}

const TextField = ({ field, control, error, isSubmitted }: Props) => {
  const settings = field.settings as unknown as FormFieldSettingsProps
  const locale = useLocale()

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel htmlFor={field.id}>
        {field.helpText}
        {field.required && " *"}
      </InputLabel>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FilledInput
            id={field.id}
            onChange={onChange}
            value={value || ""}
            error={!!error}
            multiline={settings.fieldType === "textarea"}
            rows={settings.fieldType === "textarea" ? 4 : 1}
            type={settings.fieldType === "number" ? "text" : settings.fieldType || "text"}
            inputProps={{
              dir: settings.fieldType === "tel" && locale === "he" ? "rtl" : undefined,
              autoComplete: getAutocompleteAttribute(field.alias, settings.fieldType, settings),
              inputMode: settings.fieldType === "number" ? "numeric" : undefined,
              "aria-invalid": !!error,
              "aria-describedby": `${field.id}-helper-text`,
            }}
          />
        )}
      />
      <StyledHelperText id={`${field.id}-helper-text`} status={!error}>
        {isSubmitted && error && (
          <>
            <CheckMark2Icon state={!error} />
            <span>{error?.message as string}</span>
          </>
        )}
      </StyledHelperText>
    </FormControl>
  )
}

export default TextField

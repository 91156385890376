import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { NewText, SendError, SendSuccess } from "ui"
import { sharedColors } from "ui/theme/colors"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 40px 20px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 40px;
  }
`

export type SuccessErrorProps = {
  success?: boolean
  errorMessage?: string
  buttonCallback?: () => void
  buttonHref?: string
}

const MessageComponent = ({
  success,
  errorMessage,
  buttonCallback,
  buttonHref,
}: SuccessErrorProps) => {
  const t = useTranslations("DynamicForms")

  let message = errorMessage
  let buttonText
  if (success) {
    message = t("successMessage")
    buttonText = t("successButton")
  } else if (!errorMessage) {
    message = t("ErrorMessage")
    buttonText = t("errorButton")
  }

  return (
    <StyledWrapper>
      {success ? <SendSuccess /> : <SendError />}
      <NewText
        component={"h2"}
        style={{ whiteSpace: "pre-line", textAlign: "center" }}
        desktopFontSize={32}
        lineHeight={1.5}
        desktopFontWeight={400}
        mobileFontSize={24}
        color={sharedColors.black}
        dangerouslySetInnerHTML={{ __html: message || "" }}
      ></NewText>
      <Button
        size="large"
        variant="outlined"
        href={buttonHref}
        LinkComponent={Link}
        onClick={() => {
          buttonCallback?.()
        }}
      >
        {buttonText}
      </Button>
    </StyledWrapper>
  )
}

export default MessageComponent

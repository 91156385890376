import { FormFieldDto } from "api"
import { Control, FieldError, FieldErrors, FieldValues, UseFormSetValue } from "react-hook-form"
import CheckboxField from "../Fields/CheckboxField"
import FileUpload from "../Fields/FileUpload"
import FormTitleText from "../Fields/FormTitleText"
import RadioField from "../Fields/RadioField"
import SelectField from "../Fields/SelectField"
import TextField from "../Fields/TextField"
import { StyledAcceptBox } from "../StyledComponents"
import { FormFieldSettingsProps } from "./interfaces"
import { isJustText } from "./isJustText"
import { evaluateCondition } from "./validations/evaluateCondition" // Updated import

export const renderField = (
  field: FormFieldDto,
  control: Control<FieldValues>,
  errors: FieldErrors<{
    [key: string]: any
  }>,
  isSubmitted: boolean,
  setValue: UseFormSetValue<FieldValues>,
  watchedValues: FieldValues,
) => {
  const settings = field.settings as unknown as FormFieldSettingsProps
  const error = errors[field.id]

  const shouldShow = evaluateCondition(field.condition, watchedValues) // Updated method usage

  if (field.condition?.actionType === "Show" && !shouldShow) {
    return null
  }
  if (field.condition?.actionType === "Hide" && shouldShow) {
    return null
  }

  if (field.type.renderInputType === "Multiple") {
    if (field.type.supportsPreValues) {
      return <RadioField field={field} control={control} error={error} isSubmitted={isSubmitted} />
    }
    return <SelectField field={field} control={control} error={error} isSubmitted={isSubmitted} />
  }
  if (field.type.renderInputType === "Single") {
    if (field.type.supportsUploadTypes) {
      return (
        <FileUpload
          setValue={setValue}
          formError={error as FieldError}
          isSubmitted={isSubmitted}
          onChange={(value) => setValue(field.id, value, { shouldValidate: true })}
          helpText={field.helpText}
          field={field}
        />
      )
    }
    if (isJustText(field)) {
      if (settings.html) {
        return <StyledAcceptBox dangerouslySetInnerHTML={{ __html: settings.html }} />
      } else {
        return <FormTitleText field={field} />
      }
    }
    if (settings.fieldType === undefined) {
      return (
        <CheckboxField
          field={field}
          control={control}
          error={error as FieldError}
          isSubmitted={isSubmitted}
        />
      )
    }
    return <TextField field={field} control={control} error={error} isSubmitted={isSubmitted} />
  }

  return null
}

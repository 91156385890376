/* eslint-disable no-unused-vars */
"use state"

import styled from "@emotion/styled"
import { Button, IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import { useState } from "react"
import { FilterIcon, Mobile, NewText } from "ui"
import { ProjectFilters, jobFilter } from "utils"
import FiltersModal from "./FiltersModal"
import { FilterOptionState, StateType } from "./ProjectsLayout"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  position: sticky;
  width: 100%;
  top: 64px;
  background: #fff;
  padding: 16px 0px;
  z-index: 999;
  ${(props) => props.theme.breakpoints.up("md")} {
    box-shadow: none;
    position: relative;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
  }
`

const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-width: 300px;
    display: none;
  }
`

const MoreDesignations = styled.div`
  width: fit-content;

  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => !["active"].includes(prop),
})<{ active: boolean }>`
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  font-size: 18px;
  box-sizing: border-box;
  font-weight: ${(props) => (props.active ? "600" : "400")};
  min-width: max-content;
  border: ${(props) =>
    props.active ? "1px solid transparent" : "1px solid rgba(111, 111, 111, 1)"};
  background-color: ${(props) => (props.active ? props.theme.colors.propBlue : "transparent")};
  color: ${(props) => (props.active ? "white" : "black")};
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
  }
  :hover {
    font-weight: 600;
    border-width: 1px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 0;
    flex-direction: column;
    gap: 4px;
  }
`
const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !["$count"].includes(prop),
})<{ $count: number }>`
  position: relative;
  margin: 0;
  padding: 0;
  border: ${(p) => (p.$count > 0 ? "2.4px solid black" : "none")};
  :hover {
    background-color: transparent;
  }
  :active {
    background-color: transparent;
  }
`

const CountCont = styled.div`
  position: absolute;
  text-align: center;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.propBlue};
  border-radius: 200px;
  color: white;
  font-size: 16px;
`

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.56px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`

const StyledFilterHeader = styled.h2`
  padding: 0px;
  margin: 0px;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.56px;
  text-align: center;
`

const order = [0, 1732, 1747, 5069, 1765, 1764]

function sort(values: FilterOptionState[]) {
  return values.sort((a, b) => {
    const indexA = order.indexOf(a.number)
    const indexB = order.indexOf(b.number)
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    }
    if (indexA !== -1) {
      return -1
    }
    if (indexB !== -1) {
      return 1
    }
    return 0
  })
}

function getAllSelected(values: FilterOptionState[], someCount: boolean) {
  const isAllSelected = values.every((val) => {
    if (val.number === 0) return true
    return val.visibility
  })
  const isAnySelected = values.some((val) => val.visibility && val.number !== 0)
  const onlySomeTotal = isAnySelected || someCount
  if (isAllSelected || !onlySomeTotal) {
    return true
  } else {
    return false
  }
}
type Props = {
  interimState: StateType
  label: string
  allowedFilters: any
  page: string
  filters: ProjectFilters
  onChange: (
    optionId: number,
    newVisibility: boolean,
    designation?: boolean,
    cleanSlate?: boolean,
  ) => void
  otherOnChange: (filterKey: keyof StateType, optionId: number, newVisibility: boolean) => void
  handleOtherFilters: () => void
  handleClearFilters: () => void
  handleClearInnerFilters: () => void
  countFilters: any
  count: number
  totalCount: number
  messages: {
    showAll: string
    all: string
    showResults: string
    noProjectsFound: string
    toProject: string
    filters: string
    moreCategory: string
    allProjects: string
  }
}

export default function NewFilters({
  interimState,
  onChange,
  otherOnChange,
  allowedFilters,
  count,
  totalCount,
  page,
  filters,
  handleOtherFilters,
  handleClearFilters,
  handleClearInnerFilters,
  messages,
  countFilters,
}: Props) {
  const locale = useLocale()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
    jobFilter(
      count === 0 ? messages.filters : locale === "he" ? "איפוס" : "reset",
      count === 0 ? messages.filters : locale === "he" ? "איפוס" : "reset",
      "Filter - projects",
    )
  }
  const handleClose = () => {
    setOpen(false)
    jobFilter("close modal", "close modal", "Filter - projects")
  }
  const [showAll, setShowAll] = useState(false)
  const values = interimState.designations
  const someCount = count > 0 && count !== totalCount
  const allSelected = getAllSelected(values, someCount)
  const sortedValues = sort(values).filter((el) => el.number !== 0)
  const displayValues = showAll ? sortedValues : sortedValues.slice(0, 5)
  const needMoreButton = sortedValues.length > 5
  const handleChange = (value: number, designation?: boolean) => {
    const optionId = value

    const newVisibility: boolean = values.some(
      (option) => option.number === optionId && option.visibility === true,
    )
      ? false
      : true

    onChange(optionId, newVisibility, designation)
  }
  const selectAll = () => {
    onChange(0, true, true, true)
  }
  const selectedOne = (number: number) => {
    handleChange(number, true)
  }
  return (
    <>
      <StyledContainer>
        <StyledGrid>
          <StyledButton variant="outlined" active={allSelected} onClick={selectAll}>
            {messages.allProjects}
          </StyledButton>

          {displayValues.map((el) => (
            <StyledButton
              variant="outlined"
              active={el.visibility}
              key={el.value}
              onClick={() => {
                jobFilter(el.value, "designation", "Filter - projects")
                selectedOne(el.number)
              }}
            >
              {el.value}
            </StyledButton>
          ))}
          {needMoreButton && !showAll ? (
            <MoreDesignations>
              <StyledButton
                variant="outlined"
                active={false}
                onClick={() => {
                  setShowAll(true)
                  jobFilter(messages.moreCategory, "more categoires", "Filter - projects")
                }}
              >
                + {messages.moreCategory}
              </StyledButton>
            </MoreDesignations>
          ) : null}
        </StyledGrid>
        <StyledFlex>
          <Mobile>
            <StyledFlex>
              <StyledFilterHeader>
                {locale === "he" ? "הפרויקטים שלנו" : "Our Projects"}
              </StyledFilterHeader>
            </StyledFlex>
          </Mobile>
          <FilterContainer>
            <StyledIconButton onClick={handleOpen} $count={count}>
              {count > 0 ? (
                <CountCont>
                  <span>{count}</span>
                </CountCont>
              ) : null}
              <FilterIcon />
            </StyledIconButton>
            <NewText
              style={{ borderBottom: "1px solid black" }}
              component={"span"}
              desktopFontSize={14}
              mobileFontSize={18}
              color="black"
              onClick={() => (count > 0 ? handleClearFilters() : {})}
            >
              {count === 0 ? messages.filters : locale === "he" ? "איפוס" : "Reset"}
            </NewText>
          </FilterContainer>
        </StyledFlex>

        <FiltersModal
          onChange={(filterKey, optionId, newVisibility) => {
            jobFilter(
              interimState[filterKey].filter((option) => option.number === optionId)[0]?.value,
              filterKey,
              "קריירה פילטר",
            )
            otherOnChange(filterKey, optionId, newVisibility)
          }}
          handleOtherFilters={handleOtherFilters}
          handleClearInnerFilters={handleClearInnerFilters}
          allowedFilters={allowedFilters}
          page={page}
          handleClose={handleClose}
          open={open}
          interimState={interimState}
          filters={filters}
          countFilters={countFilters}
          messages={messages}
        />
      </StyledContainer>
    </>
  )
}

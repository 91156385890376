import { FormFieldDto } from "api"

export const isJustText = (field: FormFieldDto) => {
  if (
    field.type?.name?.toLowerCase()?.includes("title") ||
    field.type?.name?.toLowerCase()?.includes("text")
  ) {
    return true
  }
  return false
}

"use client"

import styled from "@emotion/styled"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/he"
import { useTranslations } from "next-intl"
import { Dispatch, SetStateAction, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { CalendarIcon, CheckboxIcon, CheckedCheckboxIcon, CircleXIcon, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { jobFilter } from "utils"
import * as yup from "yup"
import { ApState } from "./PropertyTab"

const StyledModal = styled.form`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85vh;
  background-color: white;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.up("md")} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 660px;
    height: 584px;
  }
`
const ModalHeader = styled.div`
  background-color: ${(props) => props.theme.colors.propBlue};
  height: 68px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 60px;
  }
`
const StyledButton = styled.div`
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const ModalFooter = styled.div`
  height: 80px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #f7f7f7;
  align-items: center;
`

const ModalContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 32px;
`
const FirstPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
const SecondPart = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 64px;
  border-bottom: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
`

const Width = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 60%;
  }
`
const ThirdPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 60%;
  }
`
const DateStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const StyledNumberField = styled(TextField)`
  width: 100%;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
const StyledRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Dash = styled.div`
  width: 16px;
  height: 1px;
  background-color: #6f6f6f;
  flex-shrink: 0;
  flex-grow: 0;
`

const BodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Numbers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;

  gap: 8px;
`

const StyledToggleButton = styled(ToggleButton)`
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 8px !important;
  padding: 8px 16px 8px 16px;
  font-size: 16px;
  line-height: 20px;
  height: 48px !important;
  color: black;
  border: 1px solid #6f6f6f !important;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 14px;
  }
  &.Mui-selected {
    background-color: white;
    border: 2px solid #000000 !important;
  }
`

const CheckArray = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 80px;
    row-gap: 8px;
    min-width: 160px;
  }
`
const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ error: boolean }>`
  ${(props) =>
    props.error
      ? `  rect {
    stroke: #d32f2f;
  }`
      : ""}
`

const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-input {
    ::placeholder {
      color: #6f6f6f;
    }
  }
`

const StyledCheckFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: unset;
    gap: 8px;
    flex-direction: row;
  }
`

const StyledResetButton = styled.button`
  font-size: 16px;
  outline: none;
  color: ${(props) => props.theme.colors.propBlue};
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 18px;
  }
`
type GenericObject = { [key: string]: string }

export const buArray: GenericObject = {
  hasAccessible: "גישה לנכים",
  hasTenantRoom: "חדרי דיירים",
  hasGymboree: `ג'ימבורי`,
  hasGYM: "חדר כושר",
  hasElevator: "מעלית",
  hasCommercialFloor: "קומת מסחר",
  hasParkingLot: "חניון",
}

export const apArray: GenericObject = {
  // eslint-disable-next-line quotes
  hasMamad: 'ממ"ד',
  hasBalcony: "מרפסת",
  hasStorage: "מחסן",
  hasParking: "חניה",
  hasCentralAirConditioning: "מיזוג מרכזי",
  hasAirConditioning: "מיזוג עילי",
  hasSolarWaterHeater: "דוד שמש",
  hasBars: "סורגים",
}

export const buildingFeatures = {
  hasAccessible: "hasAccessible",
  hasTenantRoom: "hasTenantRoom",
  hasGymboree: `hasGymboree`,
  hasGYM: "hasGYM",
  hasElevator: "hasElevator",
  hasCommercialFloor: "hasCommercialFloor",
  hasParkingLot: "hasParkingLot",
} as const
export const apartmentFeatures = {
  hasMamad: "hasMamad",
  hasBalcony: "hasBalcony",
  hasStorage: "hasStorage",
  hasParking: "hasParking",
  hasCentralAirConditioning: "hasCentralAirConditioning",
  hasAirConditioning: "hasAirConditioning",
  hasSolarWaterHeater: "hasSolarWaterHeater",
  hasBars: "hasBars",
} as const

const floorArr: { value: number; label: string }[] = [{ value: 0, label: "קומת קרקע" }]

for (let i = 1; i <= 50; i++) {
  floorArr.push({ value: i, label: String(i) })
}

const roomArray = ["1", "2", "3", "4", "5", "6+"]

// Define the arrays
// const apartmentCharArray = [
//   "hasMamad",
//   "hasBalcony",
//   "hasStorage",
//   "hasParking",
//   "hasCentralAirConditioning",
//   "hasAirConditioning",
//   "hasSolarWaterHeater",
//   "hasBars",
// ]
// const buildingCharArray = [
//   "hasAccessible",
//   "hasTenantRoom",
//   "hasGymboree",
//   "hasGYM",
//   "hasElevator",
//   "hasCommercialFloor",
//   "hasParkingLot",
// ]

type Character = {
  [key: string]: yup.BooleanSchema<boolean | undefined, object>
}

// Function to create dynamic schema parts for apartment and building characteristics
const createCharSchema = (charArray: string[]) => {
  return charArray.reduce((acc, key) => {
    acc[key] = yup.boolean().optional()
    return acc
  }, {} as Character)
}

type Props = {
  open: boolean
  handleClose: () => void
  locale: string
  state: ApState
  setState: Dispatch<SetStateAction<ApState>>
  setFiltersCount: Dispatch<SetStateAction<number>>
  defaultState: ApState
  filtersCount: number
  handleReset: any
  projectPage?: boolean
  allApChar: string[]
  allBuChar: string[]
}

function FiltersModal({
  handleClose,
  open,
  locale,
  setState,
  state,
  setFiltersCount,
  defaultState,
  filtersCount,
  handleReset,
  projectPage,
  allApChar,
  allBuChar,
}: Props) {
  const apartmentCharSchema = createCharSchema(allApChar)
  const buildingCharSchema = createCharSchema(allBuChar)
  const schema = yup.object({
    floorMin: yup.number().min(0).max(50).nullable().optional(),
    floorMax: yup
      .number()
      .min(0)
      .max(50)
      .nullable()
      .optional()
      .test(
        "is-greater-than-floorMin",
        "FloorMax must be greater than or equal to FloorMin",
        function (value) {
          return !value || value >= this.parent.floorMin
        },
      ),
    areaMin: yup.number().min(0).nullable().optional(),
    areaMax: yup
      .number()
      .min(0)
      .nullable()
      .optional()
      .test(
        "is-greater-than-areaMin",
        "AreaMax must be greater than or equal to AreaMin",
        function (value) {
          return !value || value >= this.parent.areaMin
        },
      ),
    date: yup.date().optional(),
    roomNumber: yup.string().nullable().oneOf(roomArray).optional(),
    apartmentChar: yup.object(apartmentCharSchema),
    buildingChar: yup.object(buildingCharSchema),
  })
  type FormInput = yup.InferType<typeof schema>
  const t = useTranslations("FiltersModal")
  floorArr[0] = { value: 0, label: t("basement") }
  const [floorMax, setFloorMax] = useState(floorArr)
  const [floorMin, setFloorMin] = useState(floorArr)
  const [areaMin, setAreaMin] = useState<number | null>(null)
  const [areaMax, setAreaMax] = useState<number | null>(null)
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
    getValues,
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
    defaultValues: state,
  })
  const countNonObjectFields = (obj: any) => {
    let count = 0
    for (const key in obj) {
      if (key === "date" && obj["date"] !== undefined) {
        count++
      } else if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        key !== "apartmentChar" &&
        key !== "buildingChar" &&
        key !== "date" &&
        obj[key] !== null
      ) {
        count++
      }
    }
    return count
  }
  const getFiltersCount = () => {
    let totalLength = 0
    const values = getValues()
    const trueValuesCount = Object.values(values.apartmentChar).filter(
      (value) => value === true,
    ).length
    const buildingChar = Object.values(values.buildingChar).filter((value) => value === true).length

    const count = countNonObjectFields(values)
    totalLength = trueValuesCount + buildingChar + count
    setFiltersCount(totalLength)
  }
  const onSubmit: SubmitHandler<FormInput> = (data) => {
    jobFilter("Perform filtering", "Perform filtering", "פילטר מתקדם פרויקטים בשיווק ")

    const updatedData = { ...data }
    if (
      updatedData.apartmentChar["ממד"] !== undefined &&
      updatedData.apartmentChar[`ממ"ד`] !== undefined
    ) {
      // Assign the value of 'ממד' to 'ממ"ד'
      updatedData.apartmentChar[`ממ"ד`] = updatedData.apartmentChar["ממד"]
      // Delete the 'ממד' property
      delete updatedData.apartmentChar["ממד"]
    }
    Object.keys(updatedData.buildingChar).forEach((key) => {
      if (key.includes("&")) {
        const newKey = key.replace("&", "'")
        updatedData.buildingChar[newKey] = updatedData.buildingChar[key]
        delete updatedData.buildingChar[key]
      }
    })

    getFiltersCount()
    // console.log
    setState(updatedData as ApState)
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        reset()
        handleClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModal onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <NewText
            component={"h2"}
            desktopFontSize={32}
            desktopLineHeight={"36px"}
            mobileFontSize={24}
            mobileLineHeight={"28px"}
            color={sharedColors.white}
          >
            {t("sortBy")}
          </NewText>
          <StyledButton onClick={handleClose}>
            <CircleXIcon />
          </StyledButton>
        </ModalHeader>
        <ModalContent>
          <FirstPart>
            <BodyHeader>
              <NewText
                component={"h3"}
                desktopFontSize={16}
                desktopLineHeight={"20px"}
                mobileFontSize={16}
                mobileLineHeight={"17px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                {t("floor")}
              </NewText>
              <StyledRange>
                <Controller
                  name="floorMin"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel variant="filled" id="select-subject">
                        {t("starting_at")}
                      </InputLabel>
                      <Select
                        {...field}
                        label={"floorMin"}
                        labelId="select-subject"
                        variant="filled"
                        error={!!errors.floorMin}
                        fullWidth
                        // value={field.label || ""}

                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        displayEmpty
                        SelectDisplayProps={{ style: { fontSize: 16 } }}
                        inputProps={{
                          style: { fontSize: 16 },
                        }}
                        onChange={(e) => {
                          setValue("floorMin", Number(e.target.value))
                          setFloorMax(
                            floorArr.filter((floor) => floor.value >= Number(e.target.value)),
                          )
                        }}
                        onClick={() => jobFilter("החל מ-", "קומה", "פילטר מתקדם פרויקטים בשיווק ")}
                      >
                        {floorMin.map((el) => (
                          <MenuItem style={{ zIndex: 1000000 }} key={el.value} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors.floorMin?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

                <Dash />

                <Controller
                  name="floorMax"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel variant="filled" id="select-subject">
                        {t("up_to")}
                      </InputLabel>
                      <Select
                        {...field}
                        label={"floorMax"}
                        labelId="select-subject"
                        variant="filled"
                        error={!!errors.floorMin}
                        fullWidth
                        // value={field.value || ""}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        onChange={(e) => {
                          setValue("floorMax", Number(e.target.value))
                          setFloorMin(
                            floorArr.filter(
                              (floor) => floor.value <= Number(e.target.value) || floor.value === 0,
                            ),
                          )
                        }}
                        displayEmpty
                        SelectDisplayProps={{ style: { fontSize: 16 } }}
                        inputProps={{
                          style: { fontSize: 16 },
                        }}
                        onClick={() => jobFilter("עד", "קומה", "פילטר מתקדם פרויקטים בשיווק ")}
                      >
                        {floorMax.map((el) => (
                          <MenuItem style={{ zIndex: 1000000 }} key={el.value} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {errors.floorMax?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </StyledRange>
            </BodyHeader>
            <BodyHeader>
              <NewText
                component={"h3"}
                desktopFontSize={16}
                desktopLineHeight={"20px"}
                mobileFontSize={16}
                mobileLineHeight={"17px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                {t("area")}
              </NewText>
              <StyledRange>
                <Controller
                  name="areaMin"
                  control={control}
                  render={({ field }) => (
                    <StyledNumberField
                      {...field}
                      label={t("starting_at")}
                      variant="filled"
                      error={!!errors.areaMin}
                      value={field.value || ""}
                      helperText={errors.areaMin?.message}
                      onBlur={(e) => {
                        if (areaMax !== null && Number(e.target.value) > areaMax) {
                          setValue("areaMin", areaMax)
                          setAreaMin(areaMax)
                        } else {
                          setValue("areaMin", Number(e.target.value))
                          setAreaMin(Number(e.target.value))
                        }
                      }}
                      onClick={() =>
                        jobFilter("החל מ-", "שטח (במ״ר)", "פילטר מתקדם פרויקטים בשיווק ")
                      }
                    />
                  )}
                />
                <Dash />
                <Controller
                  name="areaMax"
                  control={control}
                  render={({ field }) => (
                    <StyledNumberField
                      {...field}
                      label={t("up_to")}
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 10,
                        },
                      }}
                      variant="filled"
                      value={field.value || ""}
                      error={!!errors.areaMax}
                      helperText={errors.areaMax?.message}
                      onBlur={(e) => {
                        if (areaMin !== null && Number(e.target.value) < areaMin) {
                          setValue("areaMax", areaMin)
                          setAreaMax(areaMin)
                        } else {
                          setValue("areaMax", Number(e.target.value))
                          setAreaMax(Number(e.target.value))
                        }
                      }}
                      onClick={() => jobFilter("עד", "שטח (במ״ר)", "פילטר מתקדם פרויקטים בשיווק ")}
                    />
                  )}
                />
              </StyledRange>
            </BodyHeader>
          </FirstPart>
          <SecondPart>
            <Width>
              <BodyHeader>
                <NewText
                  component={"h3"}
                  desktopFontSize={16}
                  desktopLineHeight={"20px"}
                  mobileFontSize={16}
                  mobileLineHeight={"17px"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                >
                  {t("available_date")}
                </NewText>
                <DateStyled>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field }) => {
                      return (
                        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            sx={{
                              ".MuiInputBase-root": { alignItems: "center" },
                              ".MuiOutlinedInput-root": {
                                fieldset: {
                                  borderColor: "black",
                                },
                              },
                            }}
                            slotProps={{
                              inputAdornment: { position: "start" },
                              textField: {
                                placeholder: "החל מ - ",
                              },
                            }}
                            slots={{ openPickerIcon: CalendarIcon }}
                            value={dayjs(field.value)}
                            inputRef={field.ref}
                            onChange={(date) => {
                              field.onChange(date)
                              jobFilter("החל מ-", "תאריך כניסה", "פילטר מתקדם פרויקטים בשיווק ")
                            }}
                          />
                        </LocalizationProvider>
                      )
                    }}
                  />
                </DateStyled>
              </BodyHeader>
            </Width>
            <Width>
              <Numbers>
                <BodyHeader>
                  <NewText
                    component={"h3"}
                    desktopFontSize={16}
                    desktopLineHeight={"20px"}
                    mobileFontSize={16}
                    mobileLineHeight={"17px"}
                    desktopFontWeight={600}
                    color={sharedColors.black}
                  >
                    {t("numberOfRooms")}
                  </NewText>
                  <Controller
                    name="roomNumber"
                    control={control}
                    render={({ field }) => (
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                        aria-label="numberof-rooms"
                        onChange={(event: React.MouseEvent<HTMLElement>, value: string) => {
                          setValue(field.name, value)
                          jobFilter(value, "מספר חדרים", "פילטר מתקדם פרויקטים בשיווק ")
                        }}
                      >
                        {roomArray.map((el) => (
                          <StyledToggleButton value={el} key={el}>
                            {el}
                          </StyledToggleButton>
                        ))}
                      </StyledToggleButtonGroup>
                    )}
                  />
                </BodyHeader>
              </Numbers>
            </Width>
          </SecondPart>
          <ThirdPart>
            <BodyHeader>
              <NewText
                component={"h3"}
                desktopFontSize={16}
                desktopLineHeight={"20px"}
                mobileFontSize={16}
                mobileLineHeight={"17px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                {t("aptFeatures")}
              </NewText>
              <CheckArray>
                {allApChar.map((el, index) => {
                  return (
                    <Controller
                      key={index}
                      control={control}
                      name={`apartmentChar.${el.replace(`"`, "")}`}
                      render={({ field }) => {
                        return (
                          <StyledCheckFlex>
                            <StyledCheckbox
                              error={false}
                              {...field}
                              icon={<CheckboxIcon />}
                              checkedIcon={<CheckedCheckboxIcon />}
                              checked={field.value}
                              sx={{
                                ":hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              onChange={(e) => {
                                jobFilter(el, "מאפייני דירה", "פילטר מתקדם פרויקטים בשיווק "),
                                  field.onChange(e.target.checked),
                                  setValue(`apartmentChar.${el}`, e.target.checked, {
                                    shouldTouch: true,
                                  })
                              }}
                            />
                            <NewText
                              component={"span"}
                              desktopFontSize={16}
                              desktopLineHeight={"20px"}
                              mobileFontSize={16}
                              mobileLineHeight={"17px"}
                              color={sharedColors.black}
                            >
                              {el}
                            </NewText>{" "}
                          </StyledCheckFlex>
                        )
                      }}
                    />
                  )
                })}
              </CheckArray>
            </BodyHeader>
            {projectPage ? null : (
              <BodyHeader>
                <NewText
                  component={"h3"}
                  desktopFontSize={16}
                  desktopLineHeight={"20px"}
                  mobileFontSize={16}
                  mobileLineHeight={"17px"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                >
                  {t("building_features")}
                </NewText>
                <CheckArray>
                  {allBuChar.map((el, index) => {
                    return (
                      <Controller
                        key={index}
                        control={control}
                        name={`buildingChar.${el.replace(`'`, "&")}`}
                        render={({ field }) => {
                          const isSelected =
                            field.value !== undefined
                              ? field.value
                              : getValues(`buildingChar.${el}`)
                          return (
                            <StyledCheckFlex>
                              <StyledCheckbox
                                {...field}
                                error={false}
                                icon={<CheckboxIcon />}
                                checked={isSelected}
                                checkedIcon={<CheckedCheckboxIcon />}
                                onChange={(e) => {
                                  jobFilter(
                                    el.replace(`'`, "&"),
                                    "מאפייני בניין",
                                    "פילטר מתקדם פרויקטים בשיווק ",
                                  )

                                  field.onChange(e.target.checked)
                                  setValue(
                                    `buildingChar.${el.replace(`'`, "&")}`,
                                    e.target.checked,
                                    {
                                      shouldTouch: true,
                                    },
                                  )
                                }}
                                sx={{
                                  ":hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                              <NewText
                                component={"span"}
                                desktopFontSize={16}
                                desktopLineHeight={"20px"}
                                mobileFontSize={16}
                                mobileLineHeight={"17px"}
                                color={sharedColors.black}
                              >
                                {el}
                              </NewText>
                            </StyledCheckFlex>
                          )
                        }}
                      />
                    )
                  })}
                </CheckArray>
              </BodyHeader>
            )}
          </ThirdPart>
        </ModalContent>
        <ModalFooter>
          <div>
            {(Object.keys(touchedFields).length > 0 || filtersCount > 0) && (
              <StyledResetButton
                onClick={(e) => {
                  e.preventDefault()
                  jobFilter("איפוס", "clear all button", "פילטר מתקדם פרויקטים בשיווק ")
                  reset(defaultState)
                  handleReset()
                }}
              >
                {locale === "he" ? "איפוס" : "clear"}
              </StyledResetButton>
            )}
          </div>
          <Button variant="outlined" size="large" type="submit">
            {t("apply")}
          </Button>
        </ModalFooter>
      </StyledModal>
    </Modal>
  )
}

export default FiltersModal

"use client"

import styled from "@emotion/styled"
import { FormDto, submitUmbracoForm } from "api"
import { useState } from "react"
import { Container, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import FormBuilder from "./FormBuilder"
import SuccessErrorComponent from "./SuccessError/SuccessErrorComponent"
import convertFormData from "./utils/convertFormData"

const StyledTitleWrapper = styled.div`
  padding: 16px;
  background-color: ${sharedColors.propBlue};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`
const StyledFormWrapper = styled.div`
  padding: 24px;
  background-color: ${sharedColors.lightGrey};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`

type FormMainProps = {
  data: FormDto
  locale: string
  formTitle: string
}

const FormMain = ({ data, formTitle, locale }: FormMainProps) => {
  const [formState, setFormState] = useState<"idle" | "success" | "error">("idle")
  const [formData, setFormData] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const sendForm = async (convertedData: any) => {
    const response = await submitUmbracoForm(data.id, {
      culture: locale === "he" ? "he-il" : "en-us",
      values: convertedData.values,
    })
    return response
  }

  const handleFormSubmit = async (formData: any) => {
    try {
      setLoading(true)
      const convertedData = convertFormData(formData, data)
      setFormData(formData)
      const response = await sendForm(convertedData)
      if (response && response.data && response.data.messageOnSubmit) {
        setFormState("success")
      } else {
        setFormState("error")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      // eslint-disable-next-line no-console
      console.log("Error submitting form", error)
      setFormData(formData)
      setFormState("error")
    }
  }

  const handleRetry = () => {
    setFormState("idle")
  }

  const handleGoHome = () => {}

  return (
    <Container>
      <div style={{ display: formState === "idle" ? "block" : "none" }}>
        <StyledTitleWrapper>
          <NewText
            component={"h2"}
            desktopFontSize={20}
            desktopFontWeight={600}
            color={sharedColors.white}
          >
            {formTitle}
          </NewText>
        </StyledTitleWrapper>
        <StyledFormWrapper>
          <FormBuilder
            data={data}
            locale={locale}
            onSubmit={handleFormSubmit}
            defaultValues={formData}
            loading={loading}
          />
        </StyledFormWrapper>
      </div>
      {formState === "success" && (
        <SuccessErrorComponent success buttonCallback={handleGoHome} buttonHref="/" />
      )}
      {formState === "error" && <SuccessErrorComponent buttonCallback={handleRetry} />}
    </Container>
  )
}

export default FormMain

/* eslint-disable no-use-before-define */
import { FormFieldDto, FormFieldsetDto } from "api"
import * as yup from "yup"
import { FormFieldSettingsProps } from "../interfaces"
import { evaluateCondition, getConditionFieldIds } from "./evaluateCondition"
import { isValidIsraeliID } from "./isValidIsraeliID"

export const getFieldValidation = (
  field: FormFieldDto,
  parentFieldset?: FormFieldsetDto,
  maxLength?: string,
) => {
  let validation
  if (field.type.supportsUploadTypes) {
    validation = yup
      .array()
      .of(yup.string())
      .test("fileSize", field.patternInvalidErrorMessage, (files) => {
        if (!files) return true
        return files.every((base64) => {
          const rawBase64 = base64?.replace(/^data:.+;base64,/, "")
          const byteLength = rawBase64 ? Buffer.byteLength(rawBase64, "base64") : 0
          return byteLength <= 5000000 // 5MB limit
        })
      })
  } else {
    validation = yup.string()
  }

  if (field.required) {
    validation = validation.required(field.requiredErrorMessage)
  }

  if (field.pattern && validation instanceof yup.string) {
    validation = validation.matches(new RegExp(field.pattern), field.patternInvalidErrorMessage)
  }

  const settings = field.settings as unknown as FormFieldSettingsProps
  if (settings?.maximumLength) {
    validation = validation.max(
      Number(settings.maximumLength),
      `${maxLength} ${settings.maximumLength}`,
    )
  }

  // Add pattern validation for number fields
  if (settings.fieldType === "number" && validation instanceof yup.string) {
    validation = validation.matches(/^[0-9]*$/, "Only numeric values are allowed")
  }

  if (field.alias.toLowerCase().includes("idnumber") && validation instanceof yup.string) {
    // 1. Exactly 9 digits
    validation = validation.matches(/^\d{9}$/, field.patternInvalidErrorMessage)

    // 2. Custom checksum test
    validation = validation.test(
      "israeli-id-checksum",
      field.patternInvalidErrorMessage, // or a more specific error message
      (value) => {
        // If empty, let other validations handle (like required).
        if (!value) return true
        return isValidIsraeliID(value)
      },
    )
  }

  // eslint-disable-next-line no-use-before-define

  validation = applyFieldsetCondition(validation, parentFieldset, field)

  return validation
}

/**
 * Applies the parent fieldset's condition logic using `.when()`.
 * If the fieldset is hidden, the child field is automatically stripped.
 * If shown, we proceed to the child's own condition logic.
 */
function applyFieldsetCondition(
  schema: yup.Schema<any>,
  fieldset: FormFieldsetDto | undefined,
  field: FormFieldDto,
) {
  const fieldsetCondition = fieldset?.condition
  const controllingIds = getConditionFieldIds(fieldsetCondition)

  // If there's no fieldset condition, skip straight to the child's condition
  if (!fieldsetCondition || controllingIds.length === 0) {
    // eslint-disable-next-line no-use-before-define
    return applyFieldCondition(schema, field)
  }

  return schema.when(controllingIds, {
    is: (...values: any[]) => {
      // Build a "currentValues" object to pass into evaluateCondition
      const currentValues = controllingIds.reduce<Record<string, any>>((acc, id, idx) => {
        acc[id] = values[idx]
        return acc
      }, {})

      // Is the fieldset condition satisfied?
      return evaluateCondition(fieldsetCondition, currentValues)
    },
    then: (visibleSchema) => {
      // If the fieldset condition is satisfied => actionType decides whether we show/hide
      if (fieldsetCondition.actionType === "Show") {
        // Condition satisfied => show fieldset => handle child's own condition
        // eslint-disable-next-line no-use-before-define
        return applyFieldCondition(visibleSchema, field)
      } else {
        // Condition satisfied => hide fieldset => skip all child fields
        return visibleSchema.notRequired().strip()
      }
    },
    otherwise: (hiddenSchema) => {
      // Condition not satisfied => actionType decides the opposite
      if (fieldsetCondition.actionType === "Show") {
        // Condition fails => hide fieldset => skip child fields
        return hiddenSchema.notRequired().strip()
      } else {
        // Condition fails => show fieldset => proceed
        return applyFieldCondition(hiddenSchema, field)
      }
    },
  })
}

/**
 * Applies the **child field**'s own condition. If hidden, skip; if shown, apply required logic.
 */
function applyFieldCondition(schema: yup.Schema<any>, field: FormFieldDto) {
  const fieldCondition = field.condition
  const controllingIds = getConditionFieldIds(fieldCondition)
  // If there's no child condition, just apply standard required logic
  if (!fieldCondition || controllingIds.length === 0) {
    return applyRequiredLogic(schema, field)
  }
  // console.log("evaluateCondition for field:", field.alias, controllingIds, " => ", fieldCondition)

  return schema.when(controllingIds, {
    is: (...values: any[]) => {
      const currentValues = controllingIds.reduce<Record<string, any>>((acc, id, idx) => {
        acc[id] = values[idx]
        return acc
      }, {})

      return evaluateCondition(fieldCondition, currentValues)
    },
    then: (shownSchema) => {
      // If the field's condition is satisfied => actionType "Show" means show, "Hide" means hide
      if (fieldCondition.actionType === "Show") {
        // show => apply required logic, etc.
        const result = applyRequiredLogic(shownSchema, field)
        return result
      } else {
        // hide
        return shownSchema.notRequired().strip()
      }
    },
    otherwise: (otherwiseSchema) => {
      // Condition not satisfied => do the opposite
      if (fieldCondition.actionType === "Show") {
        // hide
        return otherwiseSchema.notRequired().strip()
      } else {
        // show
        return applyRequiredLogic(otherwiseSchema, field)
      }
    },
  })
}

/**
 * If the field is required, apply .required(...).
 */
function applyRequiredLogic(schema: yup.Schema<any>, field: FormFieldDto) {
  if (field.required) {
    return schema.required(field.requiredErrorMessage)
  }
  return schema
}

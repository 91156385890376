/* eslint-disable no-unused-vars */
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Button, Checkbox, IconButton, Modal } from "@mui/material"
import { useLocale } from "next-intl"
import { useEffect, useState } from "react"
import { CheckboxIcon, CheckedCheckboxIcon, NewText, useIsDesktop, XIcon } from "ui"
import { jobFilter, ProjectFilters } from "utils"
import { FilterOptionState, StateType } from "./ProjectsLayout"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  height: 584px;
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    width: 660px;
  }

  border-radius: 16px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FiltersContainer = styled.div`
  height: 436px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px 0px 16px;
`
const CtaContainer = styled.div<{ $justify: string }>`
  border-top: 2px solid rgba(247, 247, 247, 1);
  display: flex;
  justify-content: ${(p) => p.$justify};
  padding: 16px;
  height: 80px;
`

const SingleFilterCont = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(247, 247, 247, 1);
`
const StyledFilterGrid = styled.div`
  display: grid;
  row-gap: 16px;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up("md")} {
    row-gap: 8px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`

const FilterItem = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: start;
    flex-direction: row;
  }
  gap: 16px;
`

const Header = styled.div`
  border-radius: 16px 16px 0 0;
  height: 68px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.filterModalHeaderColor};
  border-bottom: 1px solid ${(props) => props.theme.colors.propBlue};
`
const CloseButton = styled(IconButton)``

const StyledButton = styled(Button)`
  min-width: 153px;
  font-size: 18px;
  font-weight: 600;
`

const StyledShowMore = styled(Button)`
  width: fit-content;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
`

const StyledClearButton = styled(Button)`
  color: ${(props) => props.theme.colors.propBlue};
  font-size: 18px;
  outline: none;
  border: none;
  background-color: transparent;
  text-decoration: none;
`

function getAllSelected(values: FilterOptionState[]) {
  const isAllSelected = values.every((val) => {
    if (val.number === 0) return true
    return val.visibility
  })
  const isAnySelected = values.some((val) => val.visibility && val.number !== 0)
  if (isAllSelected || !isAnySelected) {
    return true
  } else {
    return false
  }
}

const sortOrder = {
  en: [
    "Residential",
    "Offices and Commercial",
    "Commercial",
    "Offices",
    "Mixed Use",
    "Public Buildings",
    "Industry and Logistics",
  ],
  he: [
    "מגורים",
    "משרדים ומסחר",
    "מסחר",
    "משרדים",
    "עירוב שימושים",
    "מבני ציבור",
    "תעשייה ולוגיסטיקה",
  ],
}

function FilterGrid({
  finalSelectedValues,
  label,
  filterKey,
  onChange,
}: {
  finalSelectedValues: FilterOptionState[]
  label: string
  filterKey: keyof StateType
  onChange: (filterKey: keyof StateType, optionId: number, newVisibility: boolean) => void
}) {
  const locale = useLocale()
  const [showAll, setShowAll] = useState(false)
  const allSelected = getAllSelected(finalSelectedValues)
  const moreThanEight = finalSelectedValues.length > 8

  const handleChange = (value: number) => {
    const optionId = value

    const newVisibility: boolean = finalSelectedValues.some(
      (option) => option.number === optionId && option.visibility === true,
    )
      ? false
      : true

    onChange(filterKey, optionId, newVisibility)
  }
  const selectAll = () => {
    onChange(filterKey, 0, true)
  }
  const selectOne = (num: number) => {
    handleChange(num)
  }
  const showSliced = !showAll && moreThanEight

  const sortedValues = finalSelectedValues
    .filter((el) => el.number !== 0)
    .sort((a, b) => {
      if (filterKey === "designations") {
        const localeOrder = sortOrder[locale as keyof typeof sortOrder] || sortOrder["en"] // Default to 'en' if locale not found

        const normalize = (str: string) => str.trim().toLowerCase()

        const indexA = localeOrder.findIndex(
          (orderItem) => normalize(orderItem) === normalize(a.value),
        )
        const indexB = localeOrder.findIndex(
          (orderItem) => normalize(orderItem) === normalize(b.value),
        )

        if (indexA === -1 && indexB === -1) return 0 // Both not found, keep original order
        if (indexA === -1) return 1 // a not found, b takes precedence
        if (indexB === -1) return -1 // b not found, a takes precedence
        return indexA - indexB // Sort according to the predefined order
      }
      return 0 // No sorting if filterKey is not 'designations'
    })
  const showValues = showSliced ? sortedValues.slice(0, 7) : sortedValues
  const theme = useTheme()
  return (
    <SingleFilterCont>
      <NewText
        component={"h4"}
        desktopFontSize={16}
        mobileFontSize={14}
        desktopFontWeight={600}
        color="black"
      >
        {label}
      </NewText>
      <StyledFilterGrid>
        <FilterItem>
          <Checkbox
            checked={allSelected}
            defaultChecked={allSelected}
            onClick={selectAll}
            checkedIcon={<CheckedCheckboxIcon color={theme.colors.propBlue} />}
            icon={<CheckboxIcon />}
          />
          <NewText
            component={"span"}
            desktopFontSize={16}
            mobileFontSize={14}
            color={allSelected ? theme.colors.propBlue : "black"}
            desktopFontWeight={allSelected ? 600 : 400}
          >
            {filterKey === "designations" && (locale === "he" ? "כל היעודים" : "All Projects")}
            {filterKey === "statuses" && (locale === "he" ? "כל הסטטוסים" : "All statuses")}
            {filterKey === "countries" && (locale === "he" ? "כל המדינות" : "All countries")}
            {filterKey === "cities" && (locale === "he" ? "כל הערים" : "All cities")}
          </NewText>
        </FilterItem>
        {showValues.map((el) => {
          return (
            <FilterItem key={el.number}>
              <Checkbox
                checked={el.visibility}
                onClick={() => selectOne(el.number)}
                checkedIcon={<CheckedCheckboxIcon color={theme.colors.propBlue} />}
                icon={<CheckboxIcon />}
              />
              <NewText
                component={"span"}
                desktopFontSize={16}
                mobileFontSize={14}
                color={el.visibility ? theme.colors.propBlue : "black"}
                desktopFontWeight={el.visibility ? 600 : 400}
              >
                {el.value}
              </NewText>
            </FilterItem>
          )
        })}
      </StyledFilterGrid>
      {showSliced ? (
        <StyledShowMore variant="text" onClick={() => setShowAll(true)}>
          {filterKey === "designations"
            ? locale === "he"
              ? "הצג יעודים נוספים"
              : "Show more designations"
            : filterKey === "cities"
            ? locale === "he"
              ? "הצגת ערים נוספות"
              : "Show more cities"
            : filterKey === "countries"
            ? locale === "he"
              ? "הצגת מדינות נוספות"
              : "Show more countries"
            : locale === "he"
            ? "הצג סטטוסים נוספים"
            : "Show more statuses"}
        </StyledShowMore>
      ) : null}
    </SingleFilterCont>
  )
}

type Props = {
  open: boolean
  handleClose: () => void
  onChange: (filterKey: keyof StateType, optionId: number, newVisibility: boolean) => void
  interimState: StateType
  allowedFilters: any
  page: string
  filters: ProjectFilters
  handleOtherFilters: () => void
  handleClearInnerFilters: () => void
  countFilters: any
  messages: {
    showAll: string
    all: string
    showResults: string
    noProjectsFound: string
    toProject: string
    filters: string
    moreCategory: string
    allProjects: string
  }
}

export default function FiltersModal({
  handleClose,
  onChange,
  open,
  allowedFilters,
  interimState,
  page,
  filters,
  handleOtherFilters,
  handleClearInnerFilters,
  countFilters,
  messages,
}: Props) {
  const isDesktop = useIsDesktop()
  const locale = useLocale()
  const keys = Object.keys(interimState).filter(
    (el) => el !== "subsidiaries" && !(isDesktop && el === "designations"),
  ) as Array<keyof StateType>
  const sm = keys.filter((key) => (allowedFilters as any)[page]?.includes(key))
  const { visibleCount } = countFilters(interimState)
  const theme = useTheme()
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [open])

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="filters" aria-describedby="filters">
      <Container>
        <Header>
          <NewText
            component={"h3"}
            desktopFontSize={32}
            mobileFontSize={24}
            color={theme.colors.filterModalHeaderTextColor}
          >
            {messages.filters}
          </NewText>
          <CloseButton onClick={handleClose}>
            <XIcon
              color={theme.colors.filterModalHeaderTextColor}
              stroke={theme.colors.filterModalHeaderTextColor}
            />
          </CloseButton>
        </Header>
        <Content>
          <FiltersContainer>
            {sm.map((filterKey) => {
              const finalSelectedValues = interimState[filterKey]
              // eslint-disable-next-line prefer-destructuring
              const label = filters[filterKey]["label"]

              return (
                <FilterGrid
                  onChange={(filterKey, optionId, newVisibility) =>
                    onChange(filterKey, optionId, newVisibility)
                  }
                  filterKey={filterKey}
                  key={label}
                  finalSelectedValues={finalSelectedValues}
                  label={label}
                />
              )
            })}
          </FiltersContainer>
          <CtaContainer $justify={visibleCount === 0 ? "flex-end" : "space-between"}>
            {visibleCount > 0 && (
              <StyledClearButton onClick={() => handleClearInnerFilters()}>
                {locale === "he" ? "איפוס" : "Clear"}
              </StyledClearButton>
            )}
            <StyledButton
              variant="outlined"
              onClick={() => {
                handleOtherFilters()
                handleClose()
                jobFilter("ביצוע סינון", "ביצוע סינון", "Filter - projects")
              }}
            >
              {locale === "he" ? "ביצוע סינון" : "Filtering"}
            </StyledButton>
          </CtaContainer>
        </Content>
      </Container>
    </Modal>
  )
}

import { FieldConditionRuleOperator, FormConditionDto } from "api"

/**
 * Return all unique field IDs needed to evaluate the condition.
 * e.g. if there are multiple rules, we'll need them all in one array.
 */
export function getConditionFieldIds(condition?: FormConditionDto): string[] {
  if (!condition || !condition.rules) return []
  const ids = condition.rules.map((r) => r.field).filter(Boolean)
  return Array.from(new Set(ids)) // remove duplicates
}

/**
 * Evaluate the entire condition with multiple rules:
 * - `logicType === "All"` means all rules must pass (`AND`).
 * - `logicType === "Any"` means at least one rule passes (`OR`).
 */
export function evaluateCondition(
  condition: FormConditionDto | undefined,
  currentValues: Record<string, any>,
): boolean {
  if (!condition || !condition.rules?.length) {
    return false
  }

  // Evaluate each rule => returns boolean array
  const results = condition.rules.map((rule) => {
    const actualValue = currentValues[rule.field]
    // eslint-disable-next-line no-use-before-define
    return evaluateRule(actualValue, rule.operator, rule.value)
  })

  if (condition.logicType === "All") {
    return results.every((r) => r === true)
  } else if (condition.logicType === "Any") {
    return results.some((r) => r === true)
  }

  // Default if logicType is null => treat as no match
  return false
}

/**
 * Evaluate a single rule by operator.
 * Adjust logic for your needed operators (string vs. number comparisons, etc.).
 */
function evaluateRule(
  actualValue: any,
  operator: FieldConditionRuleOperator,
  expectedValue: any,
): boolean {
  switch (operator) {
    case "Is":
      return actualValue === expectedValue
    case "IsNot":
      return actualValue !== expectedValue

    case "GreaterThen":
      return Number(actualValue) > Number(expectedValue)
    case "LessThen":
      return Number(actualValue) < Number(expectedValue)

    case "Contains":
      return typeof actualValue === "string" ? actualValue.includes(String(expectedValue)) : false
    case "NotContains":
      return typeof actualValue === "string" ? !actualValue.includes(String(expectedValue)) : false

    case "ContainsIgnoreCase":
      return typeof actualValue === "string"
        ? actualValue.toLowerCase().includes(String(expectedValue).toLowerCase())
        : false
    case "NotContainsIgnoreCase":
      return typeof actualValue === "string"
        ? !actualValue.toLowerCase().includes(String(expectedValue).toLowerCase())
        : false

    case "StartsWith":
      return typeof actualValue === "string" ? actualValue.startsWith(String(expectedValue)) : false
    case "NotStartsWith":
      return typeof actualValue === "string"
        ? !actualValue.startsWith(String(expectedValue))
        : false

    case "StartsWithIgnoreCase":
      return typeof actualValue === "string"
        ? actualValue.toLowerCase().startsWith(String(expectedValue).toLowerCase())
        : false
    case "NotStartsWithIgnoreCase":
      return typeof actualValue === "string"
        ? !actualValue.toLowerCase().startsWith(String(expectedValue).toLowerCase())
        : false

    case "EndsWith":
      return typeof actualValue === "string" ? actualValue.endsWith(String(expectedValue)) : false
    case "NotEndsWith":
      return typeof actualValue === "string" ? !actualValue.endsWith(String(expectedValue)) : false

    case "EndsWithIgnoreCase":
      return typeof actualValue === "string"
        ? actualValue.toLowerCase().endsWith(String(expectedValue).toLowerCase())
        : false
    case "NotEndsWithIgnoreCase":
      return typeof actualValue === "string"
        ? !actualValue.toLowerCase().endsWith(String(expectedValue).toLowerCase())
        : false

    default:
      // If operator is null or unknown, treat as false
      return false
  }
}

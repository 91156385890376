import styled from "@emotion/styled"
import { Tab, Tabs } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { sharedColors as colors } from "ui/theme/colors"
import { filterClick } from "utils"

const StyledTabs = styled(Tabs)`
  margin-top: 16px;
  .MuiTabs-flexContainer {
    display: flex;
    gap: 16px;
    ${(props) => props.theme.breakpoints.up("md")} {
      gap: 24px;
    }
  }
  .MuiTab-root {
    color: #6f6f6f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding-bottom: 0px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 2px solid transparent;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }
  .MuiTabs-scroller {
    ${(props) => props.theme.breakpoints.down("sm")} {
      overflow: scroll !important;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .Mui-selected {
    border-bottom: 2px solid ${(p) => p.theme.colors.propBlue};
    color: ${colors.black};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }
`

const StyledTab = styled(Tab)`
  /* display: none; */
  min-height: 0;
  min-width: 0;
  padding: 0 2px 8px 2px !important;
`

type Props = {
  data: any
  setValue: Dispatch<SetStateAction<number>>
  value: number
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

function BondsTabTilte({ data, setValue, value }: Props) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    filterClick(data[newValue].bondName, "אגרות חוב")
    setValue(newValue)
  }

  return (
    <StyledTabs value={value} onChange={handleChange} aria-label="person tabs">
      {data.map((block: any, index: number) => (
        <StyledTab key={index.toString()} label={block.bondName} {...a11yProps(index)} />
      ))}
    </StyledTabs>
  )
}

export default BondsTabTilte

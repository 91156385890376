import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FormFieldDto } from "api"
import { Controller } from "react-hook-form"
import { CheckMark2Icon } from "ui"
import { StyledHelperText } from "../StyledComponents"

interface Props {
  field: FormFieldDto
  control: any
  error: any
  isSubmitted: boolean
}

const SelectField = ({ field, control, error, isSubmitted }: Props) => {
  return (
    <FormControl fullWidth variant="filled" error={!!error}>
      <InputLabel htmlFor={field.id}>{field.helpText}</InputLabel>
      <Controller
        name={field.id}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            id={field.id}
            value={value || ""}
            onChange={onChange}
            inputProps={{
              "aria-invalid": !!error,
              "aria-describedby": `${field.id}-helper-text`,
            }}
          >
            {field.preValues.map((preValue) => (
              <MenuItem key={preValue.value} value={preValue.value}>
                {preValue.caption}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <StyledHelperText id={`${field.id}-helper-text`} status={!error}>
        {isSubmitted && error && (
          <>
            <CheckMark2Icon state={!error} />
            <span>{error?.message as string}</span>
          </>
        )}
      </StyledHelperText>
    </FormControl>
  )
}

export default SelectField

export function isValidIsraeliID(input: string | number): boolean {
  const id = String(input).trim()

  // Must be exactly 9 digits
  if (id.length !== 9) return false

  // (No need to pad, since we demand exactly 9 digits)

  let sum = 0
  for (let i = 0; i < 9; i++) {
    let digit = Number(id[i])
    // Multiply every second digit by 2, subtract 9 if >= 10
    if (i % 2 === 1) {
      digit *= 2
      if (digit > 9) digit -= 9
    }
    sum += digit
  }

  return sum % 10 === 0
}

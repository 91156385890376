/* eslint-disable quotes */
"use client"

import styled from "@emotion/styled"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useTranslations } from "next-intl"
import { LegacyRef, useEffect, useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { CheckMark2Icon, CloseCircleIcon, Mobile, NewText, SuccessFailJob } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import {
  jobFormClick,
  jobFormErrorClick,
  jobFormSuccessClick,
  postApply,
  schemaCommon,
} from "utils"
import * as yup from "yup"
import { ctaMessage } from "../Contact/ContactForm"
import FileUpload from "./FileUpload"
import UploadProgress from "./UploadProgress"

// eslint-disable-next-line prefer-destructuring
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
if (!RECAPTCHA_KEY) {
  throw new Error("Please provide the RECAPTCHA_KEY environment variable.")
}

const StyledForm = styled.form`
  max-width: 600px;
  width: 100%;
  min-height: 457px;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid var(--Old-Brand-Blue, ${(props) => props.theme.colors.propBlue});
  ${(props) => props.theme.breakpoints.up("md")} {
    height: fit-content;
    background: var(--White, #fff);
    /* min-width: 500px; */
  }
  input {
    font-size: 16px;
  }
  label {
    font-size: 16px;
  }
`
const StyledHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: boolean }>`
  margin-top: 4px;
  margin-bottom: 16px;
  position: static;
  color: ${(props) => (props.status ? "#008015" : "#C92418")};
  font-size: 12px;
`
const StyledTextResponse = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const StyledInsideForm = styled.div`
  padding: 24px;
  height: 100%;
  ${(props) => props.theme.breakpoints.down("md")} {
    background-color: var(--White, #fff);
  }
`

const StyledHeader = styled.div`
  height: 60px;
  background-color: ${(props) => props.theme.colors.propBlue};
  border-radius: 10px 10px 0 0;
  display: flex;
  padding: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px;
    height: 84px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    button {
      position: absolute;
      right: 16px;
    }
  }
`

const StyledAlign = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;
`

const StyledTitle = styled.div`
  margin-bottom: 16px;
`
const StyledButton = styled(Button)`
  display: flex;
  width: 153px;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px;
  border: 2px solid black;
  background: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  :hover {
    text-decoration: none;
  }
`
const schema = yup.object({
  firstname: schemaCommon.firstName,
  email: schemaCommon.email,
  phone: schemaCommon.allPhoneNumber,
  lastname: schemaCommon.lastName,
  fileName: yup
    .string()
    .required("נא לצרף קובץ קורות חיים")
    .test((value) => value?.trim().length > 0),
})
export type FormInput = yup.InferType<typeof schema>

export type FileState = {
  fileName: string | null
  fileSize: number
  file: string | null
  fileError: string | null
}
const FormComponent = ({
  orderId,
  jobType,
  messages,
  setModalOpen,
}: {
  orderId: number
  jobType?: any
  messages?: any
  setModalOpen?: any
}) => {
  const [progress, setProgress] = useState<boolean>(false)
  const [isSubmittedForm, setIsSubmittedForm] = useState(false)
  const [submitState, setSubmitState] = useState<"success" | "fail">("fail")
  const t = useTranslations("ProjectsPage")
  const [fileState, setFileState] = useState<FileState>({
    fileName: null,
    fileSize: 0,
    file: null,
    fileError: null,
  })
  const reCaptchaRef = useRef<ReCAPTCHA>()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isValid },
    reset,
    trigger,
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  })
  const { mutate } = useMutation({
    mutationKey: ["postForm"],
    mutationFn: postApply,
    onSuccess: () => {
      jobFormSuccessClick("שליחה", jobType, "טופס בעמוד משרה", false)
      setSubmitState("success")
      setIsSubmittedForm(true)
      reset()
    },
    onError: (error) => {
      jobFormErrorClick("שליחה", jobType, "טופס בעמוד משרה", error.message)
      setSubmitState("fail")
      setIsSubmittedForm(true)
      reset()
    },
  })

  useEffect(() => {
    if (messages && messages.checkbox) {
      messages.checkbox = messages.checkbox.replace(
        "privacy policy",
        '<a href="/content/privacy-policy" style="color: black">privacy policy</a>',
      )

      messages.checkbox = messages.checkbox.replace(
        "מדיניות הפרטיות",
        '<a href="/content/privacy-policy" style="color: black">מדיניות הפרטיות</a>',
      )
    }
  }, [messages])

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    if (!fileState.file || fileState.fileError) {
      return
    }
    window.scrollTo({ top: 0, behavior: "smooth" })

    reCaptchaRef?.current?.reset()
    const recaptchaResponse = (await reCaptchaRef?.current?.executeAsync()) as string

    mutate({
      cvFileBase64: fileState.file,
      email: data.email,
      fileNameWithExtension: fileState.fileName!,
      firstName: data.firstname,
      isApprovedTerms: true,
      lastName: data.lastname,
      orderId: orderId,
      phone: data.phone,
      gRecaptchaResponse: recaptchaResponse,
    })
    setProgress(true)
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {progress ? (
        <UploadProgress
          filename={fileState.fileName}
          setProgress={setProgress}
          filesize={fileState.fileSize}
          setFileState={setFileState}
        />
      ) : null}
      {!progress && isSubmittedForm ? (
        <SuccessFailJob state={submitState} setIsSubmitted={setIsSubmittedForm} />
      ) : null}
      {!progress && !isSubmittedForm ? (
        <>
          <StyledHeader>
            <Mobile>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseCircleIcon />
              </IconButton>
            </Mobile>
            <NewText
              component="p"
              desktopFontSize={32}
              desktopLineHeight="36px"
              mobileFontSize={24}
              mobileLineHeight="28px"
              color={colors.white}
            >
              {messages?.submitApp ? messages?.submitApp : "הגשת מועמדות"}
            </NewText>
          </StyledHeader>
          <StyledInsideForm>
            <StyledTitle>
              <NewText
                component="p"
                desktopFontSize={16}
                desktopLineHeight="23px"
                mobileFontSize={14}
                mobileLineHeight="20px"
                color={colors.black}
              >
                {messages?.mandatory ? messages?.mandatory : "שדות חובה*"}
              </NewText>
            </StyledTitle>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="firstname"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>
                        {messages?.firstname ? messages?.firstname : "שם פרטי*"}
                      </InputLabel>
                      <FilledInput {...field} error={!!errors.firstname} fullWidth />
                      <StyledHelperText status={!errors.firstname}>
                        {isSubmitted ? (
                          <>
                            <StyledTextResponse>
                              <CheckMark2Icon state={!errors.firstname} />
                              <span>
                                {errors.firstname ? errors.firstname.message : t("completed")}
                              </span>
                            </StyledTextResponse>
                          </>
                        ) : null}
                      </StyledHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>
                        {messages?.lastname ? messages?.lastname : "שם משפחה*"}
                      </InputLabel>
                      <FilledInput {...field} error={!!errors.lastname} fullWidth />
                      <StyledHelperText status={!errors.lastname}>
                        {isSubmitted ? (
                          <>
                            <StyledTextResponse>
                              <CheckMark2Icon state={!errors.lastname} />
                              <span>
                                {errors.lastname ? errors.lastname.message : t("completed")}
                              </span>
                            </StyledTextResponse>
                          </>
                        ) : null}
                      </StyledHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>{messages?.email ? messages?.email : "כתובת דוא״ל*"}</InputLabel>
                      <FilledInput {...field} error={!!errors.email} fullWidth />
                      <StyledHelperText status={!errors.email}>
                        {isSubmitted ? (
                          <>
                            <StyledTextResponse>
                              <CheckMark2Icon state={!errors.email} />
                              <span>{errors.email ? errors.email.message : t("completed")}</span>
                            </StyledTextResponse>
                          </>
                        ) : null}
                      </StyledHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>{messages?.phone ? `${messages?.phone}` : `טלפון*`}</InputLabel>
                      <FilledInput {...field} error={!!errors.phone} fullWidth />
                      <StyledHelperText status={!errors.phone}>
                        {isSubmitted ? (
                          <>
                            <StyledTextResponse>
                              <CheckMark2Icon state={!errors.phone} />
                              <span>{errors.phone ? errors.phone.message : t("completed")}</span>
                            </StyledTextResponse>
                          </>
                        ) : null}
                      </StyledHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  setValue={setValue}
                  formError={errors.fileName}
                  fileState={fileState}
                  setFileState={setFileState}
                  isSubmitted={isSubmitted}
                  setProgress={setProgress}
                  trigger={trigger}
                  attach={messages?.attach}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ paddingTop: "16px" }}>
                  <NewText
                    desktopFontSize={14}
                    desktopFontWeight="400"
                    desktopLineHeight="20px"
                    mobileFontSize={14}
                    mobileFontWeight="400"
                    mobileLineHeight={"12px"}
                    component="p"
                    color={"black"}
                    maxWidth={"750px"}
                    dangerouslySetInnerHTML={{
                      __html: ctaMessage(t("ctaMessage") || ""),
                    }}
                  ></NewText>
                </div>
              </Grid>
            </Grid>
            <StyledAlign>
              <StyledButton
                type="submit"
                onClick={() => {
                  jobFormClick("שליחה", jobType, "טופס בעמוד משרה")

                  Object.keys(errors).length > 0 ||
                    (!isValid &&
                      jobFormErrorClick(
                        "שליחה",
                        jobType,
                        "טופס בעמוד משרה",
                        errors?.email?.message ||
                          errors?.fileName?.message ||
                          errors?.firstname?.message ||
                          errors?.lastname?.message ||
                          errors?.phone?.message ||
                          "Fields are mandatory",
                      ))
                }}
              >
                {messages?.send ? messages?.send : "שליחה"}
              </StyledButton>
            </StyledAlign>
          </StyledInsideForm>
        </>
      ) : null}
      {RECAPTCHA_KEY && (
        <ReCAPTCHA
          ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
          sitekey={RECAPTCHA_KEY}
          size="invisible"
          hl="iw"
          tabIndex={-1}
        />
      )}
    </StyledForm>
  )
}

export default FormComponent

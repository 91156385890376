import axios from "axios"
import * as UmbracoForms from "./api/generated/UmbracoFormsInterfaces"

export interface StaticFormEntryDto {
  values: any
  contentId?: string
  culture: string
}

/**
 * Converts a GUID string without dashes into a standard GUID format with dashes.
 *
 * @param guid - The GUID string without dashes (32 characters).
 * @returns The GUID string with dashes (standard format).
 * @throws Error if the input is not a valid 32-character GUID string.
 */
function formatGuidWithDashes(guid: string): string {
  console.log(guid)
  // Check if the GUID already contains dashes
  if (/^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(guid)) {
    return guid
  }

  // Validate the input GUID
  if (!/^[a-fA-F0-9]{32}$/.test(guid)) {
    throw new Error(
      "Invalid GUID format. The input must be a 32-character hexadecimal string without dashes.",
    )
  }

  // Insert dashes at the correct positions
  return `${guid.slice(0, 8)}-${guid.slice(8, 12)}-${guid.slice(12, 16)}-${guid.slice(
    16,
    20,
  )}-${guid.slice(20)}`
}

const GRAPHQL_URL = process.env.GRAPHQL_URL?.replace("graphql", "")

export const getUmbracoForm = (id: string, locale: string) =>
  axios.get<UmbracoForms.FormDto>(`/umbraco/forms/api/v1/definitions/${formatGuidWithDashes(id)}`, {
    params: { culture: locale === "he" ? "he-il" : "en-us" },
    headers: {},
    baseURL: GRAPHQL_URL,
  })

export const submitUmbracoForm = (id: string, data: StaticFormEntryDto) =>
  axios.post<any>(`/umbraco/forms/api/v1/entries/${formatGuidWithDashes(id)}`, data, {
    baseURL: GRAPHQL_URL,
  })

import { useRef, useState } from "react"
import { Groups, MatchedStatuses, PriorityData, ProjectPage, useOutsideClick } from "utils"
import { RelatedProjects } from "utils/utils"
import ContactContent from "../../Contact/ContactContent"
import EligibilityForm from "../../Smp/EligibilityForm"
import ContactForm from "../Form"
import Partners from "../Partners"
import { ProjectsMessages } from "../Projects"
import { Backdrop, StickyCTAWrap, StickyFormWrap } from "../StandardProject"
import StickyCTA from "../StickyCTA"
import Apartments from "./Apartments"
import CTA from "./CTA"
import Description from "./Description"
import EntitledDocs from "./EntitledDocs"
import Gallery from "./Gallery"
import LegalNote from "./LegalNote"
import LotteryRegister from "./LotteryRegister"
import GoogleMap from "./Map"
import RelatedGallery from "./RelatedGallery"
import TopHeader from "./TopHeader"
import ApartmentsUmbraco from "./UmbracoApartments/Apartments"

type Props = {
  projectData: ProjectPage
  statuses: MatchedStatuses
  groups: Groups
  designations: string[]
  cities: string[]
  countries: string[]
  locale: string
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  relatedProjects?: RelatedProjects
  priorityData?: PriorityData
  toLobby?: string
  propertyTypes: {
    id: number
    value: string
  }[]
}

function Marketing({
  projectData,
  locale,
  cities,
  countries,
  designations,
  groups,
  statuses,
  formSubjects,
  messages,
  relatedProjects,
  priorityData,
  toLobby,
  propertyTypes,
}: Props) {
  const [isBackdropVisible, setIsBackdropVisible] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const stickyRef = useRef(null)
  const formRef = useRef(null)
  const entitledProject = projectData.projectType === "זכאים"
  const handleBackdropClose = () => {
    setIsFormVisible(false)
    setIsBackdropVisible(false)
    setIsDropDownOpen(false)
  }
  useOutsideClick([stickyRef, formRef], handleBackdropClose)
  const { gallery, contactAnchor } = projectData

  return (
    <>
      <TopHeader
        projectData={projectData}
        locale={locale}
        city={cities[0]}
        country={countries[0]}
        designations={designations}
        statuses={statuses}
        virtualTourLinkName={projectData.virtualTourLinkName}
        marketing
        contactAnchor={contactAnchor}
      />
      {/* {!!numbers && (
        <NumbersComponent
          data={numbers}
          textColor={sharedColors.white}
          backgroundColor={theme.colors.propBlue}
          accentColor={sharedColors.white}
          titleColor={sharedColors.white}
        />
      )} */}

      <Description projectData={projectData} locale={locale} toLobby={toLobby} />
      <Gallery gallery={gallery} locale={locale} />
      {priorityData ? (
        <Apartments
          projectData={projectData}
          priorityData={priorityData}
          locale={locale}
          formSubjects={formSubjects}
          messages={messages}
          allApChar={priorityData.allApChar}
          allBuChar={priorityData.allBuChar}
          companyId={projectData.companyId}
        />
      ) : null}
      {projectData.apartments.length > 0 ? (
        <ApartmentsUmbraco
          propertyTypes={propertyTypes}
          projectData={projectData}
          locale={locale}
          formSubjects={formSubjects}
          messages={messages}
        />
      ) : null}
      {!!projectData.lottery &&
      (projectData.lottery.boxTitle?.length || projectData.lottery.boxTextHTML?.length) ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <CTA lottery={projectData.lottery} />
      ) : null}
      <GoogleMap address={projectData.address} locale={locale} />
      {entitledProject && <EntitledDocs docs={projectData.apartmentsGeneral.apartmentContracts} />}
      {entitledProject && (
        <LotteryRegister
          lotteryWays={projectData.lotteryWays}
          lotterRegisterTitle={projectData.lotterRegisterTitle}
        />
      )}
      {entitledProject && (
        <EligibilityForm
          data={projectData.formObject}
          companyId={projectData.companyId}
          projectId={projectData?.leadFormBMBYId}
        />
      )}
      {projectData.officeInfo?.[0] ? (
        <ContactContent data={projectData.officeInfo?.[0]} project={true} />
      ) : null}

      <Partners
        messages={{
          groups: messages.groups,
          collaborators: projectData.groupPartnersTitle || "",
          entr: projectData.entrepreneursTitle || "",
        }}
        groups={groups}
        companyText={projectData.companyText}
        subTitle={projectData.subTitle2}
      />
      {isBackdropVisible && <Backdrop />}
      {!projectData.hideLeadForm && (
        <StickyCTAWrap ref={stickyRef} z={isFormVisible ? 90 : 100}>
          <StickyCTA
            projectType={projectData.projectType}
            messages={messages}
            setIsBackdropVisible={setIsBackdropVisible}
            open={isDropDownOpen}
            setIsFormVisible={setIsFormVisible}
            setIsDropDownOpen={setIsDropDownOpen}
            phone={projectData.phone}
            miniSite={projectData.miniSite}
            virtualTourLink={projectData.virtualTourLink}
            virtualTourLinkName={projectData.virtualTourLinkName}
          />
        </StickyCTAWrap>
      )}

      {isFormVisible ? (
        <StickyFormWrap ref={formRef}>
          <ContactForm
            messages={messages}
            formSubjects={formSubjects}
            onClose={handleBackdropClose}
            bmbyId={projectData?.leadFormBMBYId}
            status={projectData?.projectStatuses[0]?.name}
            companyId={projectData?.companyId}
          />
        </StickyFormWrap>
      ) : null}

      {relatedProjects && relatedProjects?.relatedProjects.length > 0 && (
        <RelatedGallery
          projectData={projectData}
          locale={locale}
          relatedObject={relatedProjects}
          toProject={messages.toProject}
        />
      )}
      <LegalNote text={projectData.legalNote || ""} />
    </>
  )
}

export default Marketing

import styled from "@emotion/styled"
import { NumbersComponent } from "ui"

const StyledDiv = styled.div``

type Props = {
  data: any
  accentColor: string
}

function InvestorsNumber({ data, accentColor }: Props) {
  return (
    <StyledDiv>
      <NumbersComponent data={data} accentColor={accentColor} />
    </StyledDiv>
  )
}

export default InvestorsNumber

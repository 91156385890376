import { FormDto } from "api"

interface FileObject {
  fileName: string
  fileContents: File
}

const convertFilesToObject = (files: File | File[], filenames: string[]) => {
  const fileArray = Array.isArray(files) ? files : [files]
  const filesResponse: FileObject[] = []

  fileArray.map((file: File, index: number) => {
    const fileName = filenames[index]
    filesResponse.push({ fileName, fileContents: file })
  })
  return filesResponse
}

const convertFormData = (formData: any, formDto: FormDto) => {
  const convertedData: any = { values: {} }
  formDto.pages.forEach((page) => {
    page.fieldsets.forEach((fieldset) => {
      fieldset.columns.forEach((column) => {
        column.fields.forEach(async (field) => {
          const { alias } = field
          if (alias && formData[field.id]) {
            if (field.type.supportsUploadTypes) {
              const filenames = formData[`${field.id}_filenames`] || []
              convertedData.values[alias] = convertFilesToObject(formData[field.id], filenames)
            } else if (field.type.name === "Recaptcha2") {
              convertedData.values["g-recaptcha-response"] = formData[field.id]
              convertedData["g-recaptcha-response"] = formData[field.id]
            } else {
              convertedData.values[alias] = Array.isArray(formData[field.id])
                ? formData[field.id]
                : [formData[field.id]]
            }
          }
        })
      })
    })
  })

  // Keep gRecaptchaResponse value without converting it
  if (formData.gRecaptchaResponse) {
    convertedData.gRecaptchaResponse = formData.gRecaptchaResponse
  }

  return convertedData
}

export default convertFormData

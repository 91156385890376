import styled from "@emotion/styled"
import { Button, ButtonBase } from "@mui/material"
import Link from "next/link"
import { useState } from "react"
import { useInView } from "react-intersection-observer"
import { Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Desktop, LeftArrow, NewText, RightArrow, useInViewConfig } from "ui"
import { ProjectPage, getNonUmbracoUrl } from "utils"
import { RelatedProjects } from "utils/utils"
import ProjectCard from "../../ProjectsLobby/ProjectCard"

const StyledSwiper = styled(Swiper)`
  padding: 0;
  padding-bottom: 24px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 32px;
  }
  .swiper-pagination {
    display: none;
  }
`

const StyledPadding = styled.div`
  padding-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  width: 290px;
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-right: 24px;
    :last-child {
      margin-right: 0;
    }
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 0px;
  }
`

const BulletsFlex = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`

const Bullet = styled.button<{ active: boolean }>`
  width: 10px;
  height: 10px;
  cursor: pointer;
  padding: 0;
  background-color: ${({ active, theme }) => (active ? theme.colors.propBlue : "transparent")};
  border-radius: 200px;
  border: ${({ active }) => (!active ? "2.3px solid #000000" : "none")};
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 14px;
    height: 14px;
  }
`
const StyledArrow = styled(ButtonBase)``
const MobilePadding = styled.div`
  ${(props) => props.theme.breakpoints.down("md")} {
    padding-left: 16px;
  }
`
type Props = {
  relatedObject: RelatedProjects | undefined
  locale: string
  toProject: string
  projectData: ProjectPage
}

export function RelatedGallery({ relatedObject, locale, toProject, projectData }: Props) {
  const [swiper, setSwiper] = useState(Object)
  const [activeIndex, setActiveIndex] = useState(0)
  const { ref, inView } = useInView(useInViewConfig)

  if (!relatedObject) return null
  const { cities, countries, relatedProjects, statuses, subsidiaries, designations } = relatedObject

  type PropsBullet = {
    pagination: any
  }
  const PaginationBullets = ({ pagination }: PropsBullet) => {
    return (
      <BulletsFlex>
        <Desktop>
          <StyledArrow onClick={() => swiper.slidePrev()}>
            {locale === "he" ? <RightArrow /> : <LeftArrow />}
          </StyledArrow>
        </Desktop>
        {pagination?.map((_: any, index: any) => (
          <Bullet
            active={index === activeIndex}
            key={index}
            // className={`bullet ${index === activeIndex ? "active" : ""}`}
            onClick={() => swiper?.slideTo(index)}
          />
        ))}
        <Desktop>
          <StyledArrow onClick={() => swiper.slideNext()}>
            {locale === "he" ? <LeftArrow /> : <RightArrow />}
          </StyledArrow>
        </Desktop>
      </BulletsFlex>
    )
  }

  return (
    <StyledPadding>
      <Container withoutPadding={true} containerRef={ref}>
        <MobilePadding>
          <NewText
            desktopFontSize={28}
            desktopFontWeight={600}
            component={"h2"}
            marginBottom={2}
            color={"black"}
          >
            {projectData?.seeMoreTitle}
          </NewText>
          <NewText
            desktopFontSize={14}
            desktopFontWeight={"semibold"}
            component={"h3"}
            marginBottom={8}
            color={"black"}
          >
            {projectData?.textUnderneathTitle}
          </NewText>
          <StyledSwiper
            slidesPerView={"auto"}
            modules={[Pagination, Navigation]}
            onSwiper={setSwiper}
            pagination
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {relatedProjects.map((el, index) => {
              return (
                <StyledSwiperSlide key={index}>
                  <ProjectCard
                    project={el}
                    countries={countries}
                    statuses={statuses}
                    subsidiaries={subsidiaries}
                    key={el.projectTitle}
                    toProject={toProject}
                    locale={locale}
                    cities={cities}
                    inView={inView}
                    designation={designations}
                  />
                </StyledSwiperSlide>
              )
            })}
          </StyledSwiper>
        </MobilePadding>
      </Container>
      <Container>
        <ButtonsContainer>
          <PaginationBullets pagination={swiper?.pagination?.bullets} />
          <Link
            href={
              projectData?.projectButton?.[0]
                ? getNonUmbracoUrl(projectData?.projectButton?.[0].url)
                : "/projects"
            }
          >
            <Button variant="outlined" size="large">
              לפרויקטים נוספים
            </Button>
          </Link>
        </ButtonsContainer>
      </Container>
    </StyledPadding>
  )
}

export default RelatedGallery

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { useLocale } from "next-intl"
import { Dispatch, SetStateAction } from "react"
import { Container, NewText } from "ui"

const StyledFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  padding-top: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 40px;
  }
`

const StyledFilterButton = styled(Button)<{ $active: boolean }>`
  height: 45px;
  border-radius: 8px;
  padding: 16px;
  background-color: ${(p) => (p.$active ? p.theme.colors.propBlue : "white")};
  border: ${(p) => (p.$active ? "none" : "1px solid black")};
  p {
    color: ${(p) => (p.$active ? "white" : "black")};
  }
  &:hover {
    background-color: ${(p) => (p.$active ? p.theme.colors.propBlue : "white")};
    text-decoration: none;
  }
  text-decoration: none;
`

type Props = {
  selectedFilters: string[]
  setSelectedFilters: Dispatch<SetStateAction<string[]>>
  availibleCities: { name: string; value: string }[]
}

function CitiesFilter({ selectedFilters, setSelectedFilters, availibleCities }: Props) {
  const locale = useLocale()
  const cities = [
    { name: locale === "he" ? "כל הערים" : "All Cities", value: "all" },
    // { name: "תל-אביב יפו", value: "תל-אביב יפו" },
    // { name: "ירושלים", value: "ירושלים" },
    // { name: "חיפה", value: "חיפה" },
    ...availibleCities,
  ]

  const handleFilterClick = (filter: string) => {
    if (filter === "all") {
      setSelectedFilters(["all"])
    } else {
      const updatedFilters = selectedFilters.filter((item) => item !== "all")
      if (selectedFilters.includes(filter)) {
        const newFilters = updatedFilters.filter((item) => item !== filter)
        setSelectedFilters(newFilters.length > 0 ? newFilters : ["all"])
      } else {
        setSelectedFilters([...updatedFilters, filter])
      }
    }
  }

  return (
    <Container>
      <StyledFilterDiv>
        {cities.map((city) => (
          <StyledFilterButton
            key={city.value}
            $active={selectedFilters.includes(city.value)}
            onClick={() => handleFilterClick(city.value)}
          >
            <NewText
              component={"p"}
              desktopFontSize={16}
              desktopLineHeight={"23px"}
              mobileFontSize={14}
              mobileLineHeight={"20px"}
              desktopFontWeight={400}
            >
              {city.name}
            </NewText>
          </StyledFilterButton>
        ))}
      </StyledFilterDiv>
    </Container>
  )
}

export default CitiesFilter

import { useEffect } from "react"
import { FieldErrors } from "react-hook-form"

const useFocusOnError = (errors: FieldErrors<any>) => {
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return errors[field] ? field : a
    }, "")

    if (firstError) {
      const element = document.getElementById(firstError)
      // eslint-disable-next-line no-console
      element?.focus()
    }
  }, [errors])
}

export default useFocusOnError

"use client"

import styled from "@emotion/styled"
import { Button } from "@mui/material"
import Link from "next/link"
import { CheckedCircleIcon, Container, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { ProjectPage } from "utils"

const StyledBox = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1.5fr 1fr;
    gap: 80px;
  }
`
const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`

const Architect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 8px;
  }
`

const GetToKnow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`
const ItemsGrid = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`
const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
    padding-top: 40px;
  }
`
const Item = styled.li`
  list-style-type: none;

  &:before {
    content: "•";
    width: 8px;
    color: transparent;
    background-color: ${(props) => props.theme.colors.propBlue};
    height: 8px;
    border-radius: 200px;
    margin-right: 8px;
    display: inline-block;
    position: relative;
    top: 5px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  p > p:first-child {
    margin-top: 0;
  }
  p > p:last-child {
    margin-bottom: 0;
  }
`

const BuildingCharDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`

const ItemChar = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 6px;
`

type Props = {
  projectData: ProjectPage
  locale: string
  toLobby?: string
}

function Description({ projectData }: Props) {
  const {
    subTitle,
    contentHTML,
    architectsOffice,
    architectsOfficeTitle,
    getToKnowTheEnvironment,
    titleGetToKnowTheEnvironment,
    buildingCharacteristicsTitle,
    buildingCharacteristicsSelection,
    moreInfoButton,
  } = projectData
  return (
    <Container>
      <StyledFlex>
        <NewText
          component={"h3"}
          desktopFontSize={32}
          desktopLineHeight="36px"
          mobileFontSize={24}
          mobileLineHeight="28px"
          color={sharedColors.black}
        >
          {subTitle}
        </NewText>
        <StyledBox>
          <Content>
            <NewText
              component={"p"}
              desktopFontSize={18}
              desktopLineHeight={1.4}
              mobileFontSize={16}
              mobileLineHeight={"23px"}
              color={sharedColors.black}
              dangerouslySetInnerHTML={{ __html: contentHTML || "" }}
            />
            {moreInfoButton && moreInfoButton.name ? (
              <Link target="_blank" href={moreInfoButton.url || ""}>
                <Button variant="outlined" size="large" style={{ fontSize: 20 }}>
                  {moreInfoButton.name}
                </Button>
              </Link>
            ) : null}
          </Content>
          <DetailsBox>
            {buildingCharacteristicsSelection.length > 0 && (
              <BuildingCharDiv>
                <NewText
                  component={"h4"}
                  desktopFontSize={16}
                  desktopLineHeight={"23px"}
                  mobileFontSize={14}
                  mobileLineHeight={"20px"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                >
                  {buildingCharacteristicsTitle}
                </NewText>
                <ItemsGrid>
                  {buildingCharacteristicsSelection.map((el) => (
                    <ItemChar key={el}>
                      <CheckedCircleIcon />
                      <NewText
                        component={"span"}
                        desktopFontSize={18}
                        desktopLineHeight={"35px"}
                        mobileFontSize={18}
                        mobileLineHeight={"24px"}
                        color={sharedColors.black}
                      >
                        {el}
                      </NewText>
                    </ItemChar>
                  ))}
                </ItemsGrid>
              </BuildingCharDiv>
            )}
            {getToKnowTheEnvironment.length > 0 && (
              <GetToKnow>
                <NewText
                  component={"h4"}
                  desktopFontSize={16}
                  desktopLineHeight={"23px"}
                  mobileFontSize={14}
                  mobileLineHeight={"20px"}
                  desktopFontWeight={600}
                  color={sharedColors.black}
                >
                  {titleGetToKnowTheEnvironment}
                </NewText>
                <ItemsGrid>
                  {getToKnowTheEnvironment.map((el) => (
                    <Item key={el}>
                      <NewText
                        component={"span"}
                        desktopFontSize={18}
                        desktopLineHeight={"35px"}
                        mobileFontSize={18}
                        mobileLineHeight={"24px"}
                        color={sharedColors.black}
                      >
                        {el}
                      </NewText>
                    </Item>
                  ))}
                </ItemsGrid>
              </GetToKnow>
            )}
            <Architect>
              <NewText
                component={"h4"}
                desktopFontSize={16}
                desktopLineHeight={"23px"}
                mobileFontSize={16}
                mobileLineHeight={"20px"}
                desktopFontWeight={600}
                color={sharedColors.black}
              >
                {architectsOfficeTitle}
              </NewText>
              <NewText
                component={"p"}
                desktopFontSize={16}
                desktopLineHeight={"24px"}
                mobileFontSize={16}
                mobileLineHeight={"24px"}
                color={sharedColors.black}
              >
                {architectsOffice}
              </NewText>
            </Architect>
          </DetailsBox>
        </StyledBox>
      </StyledFlex>
    </Container>
  )
}

export default Description

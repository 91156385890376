"use client"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import { Container, NewText, useInViewConfig, useIsDesktop, useTransitionUpStyles } from "ui"
import { sharedColors } from "ui/theme/colors"
import { styleObjectToCss } from "ui/transitions/utils"
import { extractProperty, getUmbracoPDFUrl } from "utils"

const StyledContainer = styled.div<{ noBackground?: boolean; linkColor?: string }>`
  background: ${(p) => (p.noBackground ? "none" : "white")};
  padding-bottom: 32px;
  padding-top: 32px;
  a {
    color: ${(p) => (p.linkColor ? p.linkColor : "inherit")};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
    padding-top: 32px;
  }
  h2 {
    font-size: 24px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 32px;
    }
  }
  h3 {
    font-size: 18px;
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 24px;
    }
  }

  /* Ticket https://mintapp.atlassian.net/browse/SHTRM-1824 */
  p {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`
const StyledWrapper = styled.div<{ notCentered?: boolean }>`
  background: url("/images/heroBG.png"), ${(props) => props.theme.colors.propBlue};
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 8px;
  > div > div {
    background: transparent;
    display: inline-block;
  }
  div,
  h1,
  p {
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
  }
  h1 {
    font-size: 40px;
    max-width: 675px;
  }
  div,
  p {
    font-size: 24px;
  }
  p {
    max-width: 675px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 100vw;
    margin-bottom: 44px;
    margin-left: 0;
    margin-right: 0;
    background-position-y: center;
    border-radius: unset;
    h1 {
      font-size: 48px;
    }
    div,
    p {
      font-size: 18px;
    }
  }
  ${(props) =>
    props.notCentered &&
    css`
      div,
      h1,
      p {
        margin-left: 0;
        margin-right: auto;
        text-align: start;
      }
    `}
`
type Props = {
  data: any
  noTransition?: boolean
  hasBanner: boolean
  notCentered?: boolean
  noBackground?: boolean
  linkColor?: string
}

export const ContentText: React.FC<Props> = ({
  data,
  noTransition,
  hasBanner,
  notCentered,
  noBackground,
  linkColor,
}) => {
  const isDesktop = useIsDesktop()
  const title = extractProperty(data, "conetntTitle")
  const subTitle = extractProperty(data, "subTitle")
  const innerText = extractProperty(data, "innerText")
  const { ref, inView } = useInView({
    ...useInViewConfig,
    threshold: isDesktop ? useInViewConfig.threshold : 0.1,
  })
  const titleTransitionStyles = useTransitionUpStyles(noTransition || inView)
  const subtitleTransitionStyles = useTransitionUpStyles(noTransition || inView)
  const descriptionTransitionStyles = useTransitionUpStyles(noTransition || inView)
  function cleanEmptyHtmlElements(html: string) {
    if (!html) {
      return html
    }
    // This regex finds empty HTML tags - an opening tag followed by a closing tag with no content in between
    // Note: This is a simplistic approach and may not handle nested structures or scripts/styles content properly
    const regex = /<(\w+)([^>]*)>\s*<\/\1>/g

    // Replace all instances of empty HTML elements with an empty string
    let cleanedHtml = html
    let previousHtml
    do {
      previousHtml = cleanedHtml
      cleanedHtml = cleanedHtml.replace(regex, "")
    } while (cleanedHtml !== previousHtml) // Repeat until no more replacements are made

    return cleanedHtml
  }
  const replacePDFUrlWithUmbracoUrl = () => {
    const regex = /(\/[^"]+\.(pdf|docx|doc|xls|xlsx))/g
    const newText = innerText.replace(regex, (match: any) => {
      return getUmbracoPDFUrl(match)
    })
    return cleanEmptyHtmlElements(newText)
  }
  if (!hasBanner) {
    return (
      <StyledWrapper notCentered={notCentered}>
        <StyledContainer ref={ref} noBackground={noBackground} linkColor={linkColor}>
          <Container>
            <NewText
              desktopFontSize={32}
              desktopLineHeight="36px"
              mobileFontSize={24}
              mobileLineHeight={"28px"}
              component="h2"
              color={sharedColors.black}
              cssString={styleObjectToCss(titleTransitionStyles)}
            >
              {title}
            </NewText>
            {subTitle && (
              <NewText
                desktopFontSize={18}
                desktopLineHeight="26px"
                component="h3"
                desktopFontWeight="600"
                mobileFontSize={16}
                color={sharedColors.black}
                cssString={styleObjectToCss(subtitleTransitionStyles)}
              >
                {subTitle}
              </NewText>
            )}
            <NewText
              desktopFontSize={18}
              desktopLineHeight="1.5"
              mobileFontSize={16}
              mobileLineHeight="1.5"
              component="div"
              color={sharedColors.black}
              dangerouslySetInnerHTML={{
                __html: replacePDFUrlWithUmbracoUrl(),
              }}
              cssString={styleObjectToCss(descriptionTransitionStyles)}
            />
          </Container>
        </StyledContainer>
      </StyledWrapper>
    )
  }

  return (
    <StyledContainer
      ref={ref}
      className="ContentTextAfterBanner"
      noBackground={noBackground}
      linkColor={linkColor}
    >
      <Container>
        <NewText
          desktopFontSize={32}
          desktopLineHeight="36px"
          mobileFontSize={24}
          mobileLineHeight={"28px"}
          component="h2"
          color={sharedColors.black}
          cssString={styleObjectToCss(titleTransitionStyles)}
        >
          {title}
        </NewText>
        {subTitle && (
          <NewText
            desktopFontSize={18}
            desktopLineHeight="26px"
            component="h3"
            desktopFontWeight="600"
            mobileFontSize={16}
            color={sharedColors.black}
            cssString={styleObjectToCss(subtitleTransitionStyles)}
          >
            {subTitle}
          </NewText>
        )}
        <NewText
          desktopFontSize={18}
          desktopLineHeight="1.5"
          mobileFontSize={16}
          mobileLineHeight="1.5"
          component="div"
          color={sharedColors.black}
          dangerouslySetInnerHTML={{
            __html: replacePDFUrlWithUmbracoUrl(),
          }}
          cssString={styleObjectToCss(descriptionTransitionStyles)}
        />
      </Container>
    </StyledContainer>
  )
}
export default ContentText

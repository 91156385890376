import { Checkbox, FormControl, FormControlLabel } from "@mui/material"
import { FormFieldDto } from "api"
import { Controller, FieldError } from "react-hook-form"
import { CheckMark2Icon } from "ui"
import { sharedColors } from "ui/theme/colors"
import { StyledHelperText } from "../StyledComponents"

interface Props {
  field: FormFieldDto
  control: any
  error: FieldError | undefined
  isSubmitted: boolean
}

const CheckboxField = ({ field, control, error, isSubmitted }: Props) => {
  return (
    <FormControl fullWidth error={!!error}>
      <FormControlLabel
        control={
          <Controller
            name={field.id}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                id={field.id}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
                sx={{
                  color: "black",
                  "&.Mui-checked": {
                    color: sharedColors.propBlue,
                  },
                }}
                inputProps={{
                  "aria-invalid": !!error,
                  "aria-describedby": `${field.id}-helper-text`,
                }}
              />
            )}
          />
        }
        label={`${field.required ? "* " : ""}${field.helpText}`}
      />
      <StyledHelperText id={`${field.id}-helper-text`} status={!error} marginTop={"-7px"}>
        {isSubmitted && error && (
          <>
            <CheckMark2Icon state={!error} />
            <span>{error?.message as string}</span>
          </>
        )}
      </StyledHelperText>
    </FormControl>
  )
}

export default CheckboxField

"use client"

import { GetPageQuery } from "apollo"
import { Container } from "ui"
import CustomBreadcrumbs from "ui/CustomBreadcrumbs"
import { getBreadCrumbs } from "utils"

type Props = {
  data: GetPageQuery
  locale: string
}

const Breadcrumbs = ({ data, locale }: Props) => {
  const { selfObject, parents } = getBreadCrumbs(data, locale)
  return (
    <Container>
      <CustomBreadcrumbs current={selfObject} parents={parents} />
    </Container>
  )
}

export default Breadcrumbs

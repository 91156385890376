import styled from "@emotion/styled"
import { Drawer } from "@mui/material"
import { Desktop, Mobile, useIsDesktop } from "ui"
import MessageComponent, { SuccessErrorProps } from "./MessageComponent"

const StyledWrapper = styled.div`
  padding: 40px 0;
`

const SuccessErrorComponent = ({
  success,
  errorMessage,
  buttonCallback,
  buttonHref,
}: SuccessErrorProps) => {
  const isDesktop = useIsDesktop()
  return (
    <>
      <Desktop>
        <StyledWrapper>
          <MessageComponent
            success={success}
            errorMessage={errorMessage}
            buttonCallback={buttonCallback}
            buttonHref={buttonHref}
          />
        </StyledWrapper>
      </Desktop>
      <Mobile>
        <Drawer
          anchor="bottom"
          open={!isDesktop}
          PaperProps={{
            sx: {
              borderRadius: "16px 16px 0px 0px",
            },
          }}
        >
          <MessageComponent
            success={success}
            errorMessage={errorMessage}
            buttonCallback={buttonCallback}
            buttonHref={buttonHref}
          />
        </Drawer>
      </Mobile>
    </>
  )
}

export default SuccessErrorComponent

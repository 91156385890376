import styled from "@emotion/styled"
import { FormFieldDto } from "api"
import { ElementType } from "react"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { FormFieldSettingsProps } from "../utils/interfaces"

const StyledNewText = styled(NewText)`
  ${(props) => props.theme.breakpoints.up("md")} {
    white-space: nowrap;
  }
`

type Props = {
  field: FormFieldDto
}
const FormTitleText = ({ field }: Props) => {
  const settings = field.settings as unknown as FormFieldSettingsProps
  return (
    <StyledNewText
      component={(settings.captionTag as ElementType<any>) || "p"}
      desktopFontSize={16}
      fontWeight={400}
      color={sharedColors.black}
    >
      {settings.bodyText || settings.caption}
    </StyledNewText>
  )
}

export default FormTitleText

import { yupResolver } from "@hookform/resolvers/yup"
import { Button } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { FormDto } from "api"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { NewText } from "ui"
import * as yup from "yup"

import { useTranslations } from "next-intl"
import { LegacyRef, useRef } from "react"
import { StyledForm } from "./StyledComponents"
import { FormFieldSettingsProps } from "./utils/interfaces"
import { isJustText } from "./utils/isJustText"
import { renderField } from "./utils/renderField"
import useFocusOnError from "./utils/useFocusOnError"
import { getFieldValidation } from "./utils/validations/formValidations"
import { isFieldVisible, isFieldsetVisible } from "./utils/validations/isFieldVisible"

type FormBuilderProps = {
  data: FormDto
  locale: string
  // eslint-disable-next-line no-unused-vars
  onSubmit: (formData: any) => void
  defaultValues?: any
  loading?: boolean
}

// eslint-disable-next-line prefer-destructuring
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY

const FormBuilder = ({ data, onSubmit, defaultValues, loading }: FormBuilderProps) => {
  const t = useTranslations("DynamicForms")
  const reCaptchaRef = useRef<ReCAPTCHA>()

  // Check if there is a ReCAPTCHA field in the data
  const recaptchaFieldId = data.pages.reduce<string | null>((acc, page) => {
    if (acc) return acc
    for (const fieldset of page.fieldsets) {
      for (const column of fieldset.columns) {
        for (const field of column.fields) {
          if (field.type.name === "Recaptcha2") {
            return field.id
          }
        }
      }
    }
    return null
  }, null)

  // Build a single static schema that uses .when() internally
  const schema = yup.object().shape(
    data.pages.reduce((acc: { [key: string]: yup.Schema }, page) => {
      page.fieldsets.forEach((fieldset) => {
        fieldset.columns.forEach((column) => {
          column.fields.forEach((field) => {
            if (isJustText(field) || field.type.name === "Recaptcha2") return // skip HTML-only and ReCAPTCHA
            acc[field.id] = getFieldValidation(field, fieldset, t("maxLength"))
          })
        })
      })
      return acc
    }, {}),
  )

  // Initialize the form with default values
  const initialValues = data.pages.reduce((acc: { [key: string]: any }, page) => {
    page.fieldsets.forEach((fieldset) => {
      fieldset.columns.forEach((column) => {
        column.fields.forEach((field) => {
          const settings = field.settings as unknown as FormFieldSettingsProps
          if (settings.defaultValue) {
            acc[field.id] = settings.defaultValue
          }
        })
      })
    })
    return acc
  }, {})

  const formDefaultValues = defaultValues || initialValues

  // Initialize the form using the single schema
  const {
    control,
    setValue,
    formState: { errors, isSubmitted },
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: formDefaultValues,
  })

  const watchedValues = watch()

  useFocusOnError(errors)

  const handleFormSubmit = handleSubmit(async (formData) => {
    let recaptchaResponse = ""
    if (recaptchaFieldId) {
      recaptchaResponse = (await reCaptchaRef?.current?.executeAsync()) as string
      formData[recaptchaFieldId] = recaptchaResponse
    }
    onSubmit(formData)
  })

  return (
    <StyledForm onSubmit={handleFormSubmit}>
      {data.pages.map((page, pageIndex) => (
        <div key={pageIndex}>
          {page.fieldsets.map((fieldset) => {
            if (!isFieldsetVisible(fieldset, watchedValues)) {
              return null
            }
            // Find the maximum number of fields across all columns
            const maxFieldCount = Math.max(...fieldset.columns.map((col: any) => col.fields.length))

            return (
              <div key={fieldset.id} style={{ marginBottom: 16 }}>
                <NewText
                  component="h3"
                  desktopFontSize={18}
                  desktopFontWeight={400}
                  color="black"
                  marginBottom={2}
                >
                  {fieldset.caption}
                </NewText>
                {/* Render fields row-by-row, pulling the same index from each column */}
                {Array.from(Array(maxFieldCount).keys()).map((rowIndex) => (
                  <Grid container spacing={3} key={rowIndex}>
                    {fieldset.columns.map((column: any, columnIndex: number) => {
                      const field = column.fields[rowIndex]
                      if (
                        !field ||
                        !isFieldVisible(field, watchedValues) ||
                        field.type.name === "Recaptcha2"
                      )
                        return null
                      return (
                        <Grid xs={12} md={12 / fieldset.columns.length} key={columnIndex}>
                          <div style={{ marginBottom: 8 }}>
                            {renderField(
                              field,
                              control,
                              errors,
                              isSubmitted,
                              setValue,
                              watchedValues,
                            )}
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                ))}
              </div>
            )
          })}
        </div>
      ))}
      <Grid container justifyContent="flex-end" spacing={0}>
        <Grid>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            disabled={loading}
          >
            {loading ? t("loading") || "" : data.submitLabel}
          </Button>
        </Grid>
      </Grid>
      {recaptchaFieldId && RECAPTCHA_KEY && (
        <ReCAPTCHA
          ref={reCaptchaRef as LegacyRef<ReCAPTCHA> | undefined}
          sitekey={RECAPTCHA_KEY}
          size="invisible"
          hl="iw"
          tabIndex={-1}
        />
      )}
    </StyledForm>
  )
}

export default FormBuilder
